import axios from "axios";
import RemoveCredentials from "./Components/GlobalComponent/RemoveCredentials";

const instance = axios.create({
  baseURL: "https://mybuddy.botmantra.ai/backend/backend",
  // baseURL: "http://localhost:5000/backend",
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log(error.response);
    if (error.response.data.msg === "Token has expired") {
      alert("Session Time Out , Login Again");
      RemoveCredentials();
    }
    return Promise.reject(error);
  }
);

export default instance;
