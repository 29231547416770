import React, { useState } from "react";
import Box from "@mui/material/Box";
// import { alpha, styled } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";

import axios from "../../Axios";
import CustomeBox from "../ui/CustomeBox";
import InputLabel from "@mui/material/InputLabel";
import "./changePassword.css";
// import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import RemoveCredentials from "../GlobalComponent/RemoveCredentials";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import Controls from "../../Components/ui/Controls/Controls";

const ChangePassword = () => {
  const history = useHistory();

  const [currentPwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmCurrentPwd, setConfirmCurrentPwd] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [regexError, setRegexError] = useState(false);

  // const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  const changePasswordHandler = async (e) => {
    // e.preventdefault();
    setError(false);
    // setRegexError(false);
    setErrorMessage("");
    try {
      // if (regex.test(newPwd)) {
      if (newPwd === confirmCurrentPwd) {
        const response = await axios.post("/changePassword", {
          current_password: currentPwd,
          new_password: newPwd,
        });
        if (response.data.status === true) {
          alert(response.data.message);
          // toast.success(response.data.message, Toaster);
          RemoveCredentials();
          history.push("/login");
        } else if (response.data.status === "error") {
          toast.error(response.data.message, Toaster);
        } else {
          toast.error(response.data.message, Toaster);
        }
        setCurrentPwd("");
        setNewPwd("");
        setConfirmCurrentPwd("");
      } else {
        setErrorMessage("New Password and Confirm Password does not match");
      }
      // } else {
      // setRegexError(true);
      // }
    } catch (error) {
      setError(true);
    }
  };

  const navigateToFormHandler = () => {
    if(localStorage.getItem("status") === "Admin") {
    history.push("/leaveApproval")
    } else {
    history.push("/timeSheet")
    }
    setCurrentPwd("");
    setNewPwd("");
    setConfirmCurrentPwd("");
  };

  if (error) {
    console.log("Error in change password : ", error);
  }

  return (
    <div>
      <ToastContainer />
      <div style={{ width: "75%", margin: "auto" }}>
        <CustomeBox BoxHeader="Change Password">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 0.5, width: "32ch" },
            }}
            style={{ textAlign: "center", marginBottom: "0.5rem" }}
            noValidate
            autoComplete="off"
          >
            <div >
              {errorMessage && (
                <p style={{ backgroundColor: "#FFCCCC" , padding:"10px" }}>{errorMessage}</p>
              )}
            </div>
            <div style={{ marginTop: "1rem" }}>
              <InputLabel
                style={{ width: "116%", fontSize: "1.25rem" }}
                shrink
                htmlFor="bootstrap-input"
              >
                Current Password
              </InputLabel>
              <TextField
                id="standard-size-small"
                variant="standard"
                size="small"
                type="password"
                name="current_password"
                value={currentPwd}
                onChange={(e) => setCurrentPwd(e.target.value)}
                autoComplete="current-password"
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <InputLabel
                style={{ width: "113%", fontSize: "1.25rem" }}
                shrink
                htmlFor="bootstrap-input"
              >
                New Password
              </InputLabel>
              <TextField
                id="standard-size-small"
                variant="standard"
                size="small"
                name="new_password"
                type="password"
                value={newPwd}
                onChange={(e) => {
                  // setRegexError(false);
                  setNewPwd(e.target.value);
                  setErrorMessage("")
                }}
                autoComplete="new_password"
              />
            </div>
            {/* {regexError ? (
              <p className="regexError">
                Password must be atleast 8 digit and contain one capital
                character,One small character,One special character(!@#$%^&*)
                and One number
              </p>
            ) : (
              ""
            )} */}
            <div style={{ marginTop: "1rem" }}>
              <InputLabel
                style={{ width: "121%", fontSize: "1.25rem" }}
                shrink
                htmlFor="bootstrap-input"
              >
                Confirm New Password
              </InputLabel>
              <TextField
                id="standard-size-small"
                variant="standard"
                size="small"
                type="password"
                name="confirmNewPwd"
                value={confirmCurrentPwd}
                onChange={(e) => {setConfirmCurrentPwd(e.target.value);setErrorMessage("")}}
                autoComplete="confirm-new-password"
              />
            </div>
            <div style={{ float: "center", marginTop: "2rem" }}>
              <Controls.Button
                style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                onClick={changePasswordHandler}
                text="submit"
              />

              <Controls.ButtonV2
                style={{ marginLeft: "1rem" }}
                onClick={navigateToFormHandler}
                text="cancel"
              />
            </div>
          </Box>
        </CustomeBox>
      </div>
    </div>
  );
};

export default ChangePassword;
