import React from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CustomeModal from "../../Components/ui/CustomeModal";
import Controls from "../../Components/ui/Controls/Controls";

export default function AddProject({
  handleAddFormChange,
  open,
  setOpen,
  error,
  setError,
  handleAddFormSubmit,
}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 700,
  //   bgcolor: "background.paper",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  //   p: 4,
  // };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Controls.Button
          style={{ margin: "12px 0" }}
          onClick={handleOpen}
          text="Add Project"
        />
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomeModal BoxHeader="Add Project">
          <form>
            {error && (
              <p style={{ color: "red" }}>
                Please fill all the mandatory fields
              </p>
            )}

            <Grid
              container
              spacing={2}
              style={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
              <Grid item xs={2}>
                <label>WBS Code </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="wbs_code"
                  name="wbs_code"
                  onChange={handleAddFormChange}
                />
              </Grid>

              <Grid item xs={2}>
                <label>WBS Description</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    padding: "10px",
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="wbs_description"
                  name="wbs_description"
                  onChange={handleAddFormChange}
                />
              </Grid>
            </Grid>
            <div style={{ textAlign: "right", marginTop: "3rem" }}>
              <Controls.Button
                variant="contained"
                onClick={handleAddFormSubmit}
                text="save"
              />

              <Controls.ButtonV2
                variant="outlined"
                style={{ marginLeft: "1rem" }}
                onClick={(e) => setOpen(false)}
                text="cancel"
              />
            </div>
          </form>
        </CustomeModal>
      </Modal>
    </div>
  );
}
