import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MyLoginPage from "./Components/login/login";
import RestPassword from "./Components/ResetPassword/ResetPassword";
import Home from "./containers/home/home";
import TimeSheet from "./containers/TimeSheet/timeSheet";
import Layout from "./Components/layout/layout";
import ChangePassword from "./Components/changePassword/ChangePassword";
import LeaveMgmt from "./containers/leaveMgmt/LeaveMgmt";
import EmpList from "./containers/empMaster/EmpList";
import ProjectMaster from "./containers/projectMaster/ProjectMaster";
import LeaveTransaction from "./containers/LeaveTransaction/LeaveTransaction";
import HolidayList from "./containers/HolidayCalender/HolidayList";
import LeaveApprovalTb from "./containers/LeaveTransaction/leaveApprovalTb";
import MarkAttendance from "./containers/MarkAttendance/MarkAttendance";
import LeaveHistoryAdmin from "./containers/LeaveHistoryAdmin/LeaveHistoryAdmin";
import Download_Attendance_sheet from "./containers/Download_Attendance_sheet/Download_Attendance_Sheet";

function App() {
  let [name, setName] = useState(null);
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <MyLoginPage setName={setName} />
        </Route>

        <Route path="/ResetPassword/:Email" exact>
          <RestPassword/>
        </Route>

        <Layout name={name} setName={setName}>
          <Route path="/leaveMgmt" component={LeaveMgmt} />
          <Route path="/timeSheet" component={TimeSheet} />

          <Route path="/leave-history" component={LeaveHistoryAdmin} />

          <Route path="/home" component={Home} />
          <Route path="/changePassword" component={ChangePassword} />
          <Route path="/holidays" component={HolidayList} />
          
          <Route path="/mark-attendance" component={MarkAttendance} />
          <Route path="/download-attendance-sheet" component={Download_Attendance_sheet} />

          <Route path="/empList" component={EmpList} />
          <Route path="/projectMaster" component={ProjectMaster} />
          <Route path="/leaveTransaction" component={LeaveTransaction} />
          <Route path="/leaveApproval" component={LeaveApprovalTb} />
        </Layout>
      </Switch>
    </Router>
  );
}

export default App;
