import React, { useRef } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidatedDatePicker from "../../Components/ui/ValidatedDatePicker";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CustomeModal from "../../Components/ui/CustomeModal";
import Controls from "../../Components/ui/Controls/Controls";
import IconButton from "@mui/material/IconButton";

const AddNewEmployee = ({
  handleAddFormChange,
  setDOB,
  setDOE,
  setDOJ,
  doj,
  doe,
  dob,
  handleAddFormSubmit,
  error,
  open,
  setOpen,
  setError,
}) => {
  const inputEl = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDOBHandler = (date) => {
    const pad = "00";
    const yy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    setDOB(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
  };

  const handleDOJHandler = (date) => {
    const pad = "00";
    const yy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    setDOJ(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
  };

  const handleDOEHandler = (date) => {
    const pad = "00";
    const yy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    setDOE(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
  };

  const handle = () => {};

  const handleSucess = (e) => {
    handleAddFormSubmit(e);
  };

  const handleClearDataForDOEDate = () => {
    setDOE("");
  };

  return (
    <div>
      <div>
        <div
          style={{ float: "right", display: "inline", marginBottom: "1rem" }}
        >
          <Controls.Button
            fullWidth={true}
            style={{ margin: "10px 0" }}
            onClick={handleOpen}
            text=" Add Employee"
          />
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomeModal BoxHeader="Add Employee">
          <ValidatorForm
            ref={inputEl}
            onSubmit={handle}
            onError={(errors) => console.log(errors)}
          >
            {error && (
              <p style={{ color: "red" }}>
                Please fill all the mandatory fields
              </p>
            )}

            <Grid
              container
              spacing={2}
              style={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
              <Grid item xs={2}>
                <label>Employee Id </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="number"
                  id="empId"
                  name="emp_id"
                  onChange={handleAddFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <lable className="labelStyles">DOB</lable>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={3.5}>
                <ValidatedDatePicker
                  style={{ marginLeft: "2rem" }}
                  value={dob ?? ""}
                  onChange={(date) => {
                    handleDOBHandler(date);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Employee Email</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="empEmail"
                  name="emp_email"
                  onChange={handleAddFormChange}
                />
              </Grid>

              <Grid item xs={2}>
                {" "}
                <lable className="labelStyles">DOJ</lable>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={3.5}>
                <ValidatedDatePicker
                  style={{ marginLeft: "0.1rem" }}
                  value={doj ?? ""}
                  onChange={(date) => {
                    handleDOJHandler(date);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Employee Name </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="empName"
                  name="emp_name"
                  onChange={handleAddFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <lable className="labelStyles">Mobile No.<sup style={{color:"Red"}}>*</sup></lable>
              </Grid>
              <Grid item xs={3.5}>
              <input
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="phone_number"
                  // pattern="^[0-9]{10}$"
                  name="phone_number"
                  onChange={handleAddFormChange}
                />
              </Grid>

              <Grid item xs={2}>
                <label>Telegram ID<sup style={{color:"red"}}>*</sup>: </label>
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="telegram_id"
                  name="telegram_id"
                  onChange={handleAddFormChange}
                />
              </Grid>

              {/* <Grid item xs={2}>
                <lable className="labelStyles">DOE</lable>
              </Grid>
              <Grid item xs={3.5}>
                <ValidatedDatePicker
                  style={{ width: "100%" }}
                  value={doe ?? ""}
                  onChange={(date) => {
                    handleDOEHandler(date);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={0.5}
                style={{
                  padding: "0px",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <IconButton size="small">
                  <div
                    onClick={handleClearDataForDOEDate}
                    style={{
                      fontSize: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    X
                  </div>
                </IconButton>
              </Grid> */}
            </Grid>
            <div style={{ textAlign: "right", marginTop: "3rem" }}>
              <Controls.Button
                style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                onClick={handleSucess}
                text="save"
              />

              <Controls.ButtonV2
                style={{ marginLeft: "1rem" }}
                onClick={(e) => {
                  setOpen(false);
                  setDOB("");
                  setDOE("");
                  setDOJ("");
                  setError(false);
                }}
                text="cancel"
              />
            </div>
          </ValidatorForm>
        </CustomeModal>
      </Modal>
    </div>
  );
};

export default AddNewEmployee;
