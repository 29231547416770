import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { nanoid } from "nanoid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import Controls from "../../Components/ui/Controls/Controls";

const NewFormTable = ({
  wbsMaster,
  setUserWbsList,
  userWbsList,
  setIsAddButtonEnabled,
}) => {
  const [selectedWbsCode, setSelectedWbsCode] = useState("");
  const [selectedWbsDescription, setStateWbsDescription] = useState("");
  const [percentage, setPercentage] = useState("");

  const wbsCodeHandler = (e) => {
    setSelectedWbsCode(e.target.value);
    const result = wbsMaster.filter((item) => item.wbs_code === e.target.value);
    setStateWbsDescription(result[0].wbs_description);
  };

  const handleChange = () => {
    const newForm = {
      wbs_id: nanoid(),
      wbs_code: selectedWbsCode,
      wbs_description: selectedWbsDescription,
      wbs_percentage: Number(percentage),
    };

    const index = userWbsList.findIndex(
      (item) => item.wbs_code === newForm.wbs_code
    );
    if (index === -1) {
      userWbsList.push(newForm);
    } else {
      userWbsList[index] = newForm;
    }
    setIsAddButtonEnabled(false);
    setUserWbsList(userWbsList);
  };

  const addNewWbsHandler = () => {
    if (
      selectedWbsCode !== "" &&
      selectedWbsDescription !== "" &&
      percentage !== ""
    ) {
      handleChange();
    } else if (!selectedWbsCode && !percentage) {
      toast.error("please fill all the fields", Toaster);
    }
    setSelectedWbsCode("");
    setStateWbsDescription("");
    setPercentage("");
  };

  return (
    <tr>
      <TableCell align="left" style={{ width: "35%" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select WBS</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={30}
            value={selectedWbsCode}
            onChange={wbsCodeHandler}
            label="Select WBS"
            size="small"
          >
            {wbsMaster.map((item) => (
              <MenuItem key={item._id} value={item.wbs_code}>
                {item.wbs_code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell align="left" style={{ width: "30%" }}>
        <TextField
          id="outlined-size-small"
          size="small"
          variant="outlined"
          label="WBS Description"
          value={selectedWbsDescription}
          readOnly
        />
      </TableCell>
      <TableCell align="left" style={{ width: "25%" }}>
        <TextField
          id="outlined-number"
          variant="outlined"
          size="small"
          type="number"
          value={percentage}
          onChange={(event) => setPercentage(event.target.value)}
          label="Enter Percentage"
          onWheel={(e) => e.target.blur()}
        />
      </TableCell>
      <TableCell>
        <Controls.Button
          style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
          onClick={addNewWbsHandler}
          text="add"
        />
      </TableCell>
    </tr>
  );
};

export default NewFormTable;
