import React from "react";

export default function CustomeBox(props) {
  const { BoxHeader, BoxFooter } = props;
  const boxStyle = {
    margin: "1rem auto 10px",
    boxShadow: "1px 3px 8px 0px #ddd",
    borderRadius: "5px",
    background: "#fff",
    width:"100%",
    
  };

  const BoxHeaderStyle = {
    paddingLeft: "10px",
    paddingTop: "10px",
    paddingBottom: "5px",
    background: "#343A40",
    borderRadius: "5px 5px 0px 0px",
    color:'#fff',
    borderBottom:'3px solid #F29535'
  };
  const BoxContentStyle = {
    padding: "10px 20px 20px 20px",
  };

  return (
    <>
      <div style={boxStyle}>
        <div className="boxHeader" style={BoxHeaderStyle}>
          <h5>{BoxHeader}</h5>
        </div>
        <div className="box-content" style={BoxContentStyle}>
          {props.children}
        </div>
        <div className="box-footer">{BoxFooter}</div>
      </div>
    </>
  );
}
