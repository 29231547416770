import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Paper from '@mui/material/Paper';

const CustomCard = (props) => {
  return (
    <Paper elevation={2}>
    <div
      style={{
        width: "100%",
        height: "84.68px",
        background: `${props?.backgroundcolor}`,
        borderRadius: "3px 3px 0px 0px",
        // border:`0.5px solid ${props?.bottomColor}`,
        borderBottom: `5px solid ${props?.bottomColor}`,
        
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: "black",
          padding:"15px"
        }}
      >
        <div>{props?.title}</div>

        <div style={{display:"flex" }}>
          <div >
            {props?.value <= 0 ? (
              <KeyboardArrowDownIcon
                style={{ color: "red" }}
                fontSize="large"
              />
            ) : (
              <KeyboardArrowUpIcon
                style={{ color: "green" }}
                fontSize="large"
              />
            )}
          </div>
          <div style={{width:"35px" , height:"35px" , paddingTop:"5px" ,boxSizing:"inherit" , verticalAlign:"middle" ,display:"inline-block" }}>
            <div
              style={{
                display:"inline-block" ,
                fontSize: "large",
                // margin:"auto"
              }}
            >
              <strong>{props?.value}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Paper>
  );
};

export default CustomCard;
