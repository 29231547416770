import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Controls from "../../Components/ui/Controls/Controls";

const ReadOnlyRow = ({ wbs, clickEditHandler, handleDeleteItem , index }) => {
  return (
    <>
      <TableRow>
        <TableCell align="center" component="th" scope="row">
          {wbs.wbs_code}
        </TableCell>
        <TableCell align="center">{wbs.wbs_description}</TableCell>
        <TableCell align="center">{wbs.wbs_percentage}</TableCell>
        <TableCell align="center" style={{ width: "100%" }}>
        <span class="material-symbols-outlined" style={{cursor:"pointer" , color:"#343A40"}} 
            onClick={(event) => clickEditHandler(event, wbs.wbs_code)}>
            edit_square
        </span>
        
        {index > 0 && (
          <span class="material-symbols-outlined" style={{cursor:"pointer" , color:"#343A40" ,paddingLeft:"5px"}}
          onClick={(event) => handleDeleteItem(event, wbs.wbs_code)}
          >
          delete_forever
          </span>
        )}
        
          {/* <Controls.ActionButton
            color="primary"
            onClick={(event) => clickEditHandler(event, wbs.wbs_code)}
          >
            <EditOutlinedIcon style={{ color: "black" }} fontSize="small" />
          </Controls.ActionButton>

          <Controls.ActionButton
            style={{ backgroundColor: "red" }}
            onClick={(event) => handleDeleteItem(event, wbs.wbs_code)}
          >
            <CloseIcon
              style={{ color: "black"}}
              fontSize="small"
            />
          </Controls.ActionButton> */}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReadOnlyRow;
