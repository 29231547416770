import React, { useState } from "react";
import Grid from "@mui/material/Grid";

import Modal from "@mui/material/Modal";
import CustomeModal from "../../Components/ui/CustomeModal";
import axios from "../../Axios";
import Controls from "../../Components/ui/Controls/Controls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";

const EditLeave = ({ setIsEdit, isEdit, editData, getLeaveTransaction }) => {
  const [error, setError] = React.useState(false);

  const [editFormData, setEditFormData] = useState({
    emp_id: editData.emp_id,
    emp_email: editData.emp_email,
    emp_name: editData.emp_name,
    el: editData.el_bal.toString(),
    cl: editData.cl_bal.toString(),
    co: editData.co_bal.toString(),
    ml: editData.ml_bal.toString(),
    pl: editData.pl_bal.toString(),
  });

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleEditFormSubmit = async () => {
    if (
      editFormData.emp_id === "" ||
      editFormData.emp_email === "" ||
      editFormData.emp_name === "" ||
      editFormData.el === "" ||
      editFormData.cl === "" ||
      editFormData.co === "" ||
      editFormData.ml === "" ||
      editFormData.pl === ""
    ) {
      setError(true);
      setIsEdit(true);
    } else {
      try {
        const response = await axios.post("/updateLeavetrans", editFormData);
        if (response.data.status === true) {
          toast.success("Successfully Updated Leaves", Toaster);
          // history.push("/leaveTransaction");
          getLeaveTransaction();
        } else if (response.data.status === false) {
          toast.error(response.data.message, Toaster);
        }
        setError(false);
        setIsEdit(false);
      } catch (err) {
        toast.error(err, Toaster);
      }
    }
  };

  const handleSucess = (e) => {
    setIsEdit(false);
    handleEditFormSubmit(e);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        open={isEdit}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomeModal BoxHeader="Edit Leave">
          <form>
            {error && (
              <p style={{ color: "red" }}>
                Please fill all the mandatory fields
              </p>
            )}

            <Grid
              container
              spacing={2}
              style={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
              <Grid item xs={2}>
                <label>Employee Id </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="number"
                  id="empId"
                  name="emp_id"
                  readOnly
                  value={editFormData.emp_id}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Employee Email</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="text"
                  id="empEmail"
                  name="emp_email"
                  readOnly
                  value={editFormData.emp_email}
                />
              </Grid>

              <Grid item xs={2}>
                <label>Employee Name </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="text"
                  id="empName"
                  name="emp_name"
                  readOnly
                  value={editFormData.emp_name}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Earned Leave </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="number"
                  id="el"
                  name="el"
                  value={editFormData.el}
                  onChange={handleEditFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Casual Leave </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="number"
                  id="cl"
                  name="cl"
                  value={editFormData.cl}
                  onChange={handleEditFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Comp Off </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="number"
                  id="co"
                  name="co"
                  value={editFormData.co}
                  onChange={handleEditFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Paternity Leave </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="number"
                  id="pl"
                  name="pl"
                  value={editFormData.pl}
                  onChange={handleEditFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Maternity Leave </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="number"
                  id="ml"
                  name="ml"
                  value={editFormData.ml}
                  onChange={handleEditFormChange}
                />
              </Grid>
            </Grid>

            <div style={{ textAlign: "right", marginTop: "3rem" }}>
              <Controls.Button
                style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                onClick={handleSucess}
                text="save"
              />

              <Controls.ButtonV2
                style={{ marginLeft: "1rem" }}
                onClick={handleCancel}
                text="cancel"
              />
            </div>
          </form>
        </CustomeModal>
      </Modal>
    </div>
  );
};

export default EditLeave;
