import React from 'react'
import { Button as MuiButton, makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        // marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        background:'#343A40',
        border:'1px solid #F29634',
        '&:hover':{
            background:'#F29634',
            color:'#343A40',
            fontWeight:'bold'
        }
    },
    
    label: {
        // textTransform: 'none'
    }
}))

export default function Button(props) {

    const { text, size, color, variant, onClick, ...other } = props
    const classes = useStyles();

    return (
        <MuiButton
            variant={variant || "contained"}
            size={size}
            color={color || "primary"}
            onClick={onClick}
            {...other}
            classes={{ root: classes.root, label: classes.label }}>
            {text}
        </MuiButton>
    )
}
