
import ActionButton from "./ActionButton";
import Button from "./Button";
import  ButtonV2 from './ButtonV2'

const Controls = {
    ActionButton,
    Button,
    ButtonV2
}
export default Controls;