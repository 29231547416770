import React, { useState, useEffect, useRef } from "react";
import axios from "../../Axios";
import { Grid, Box, TableContainer, Table } from "@mui/material";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./timeSheet.css";
import { useHistory } from "react-router-dom";
import Card from "../../Components/customCard";
import CustomTableBody from "../../Components/table/NewDataTableBody";
import ExistingDataTableBody from "../../Components/table/ExistingDataTableBody";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidatedDatePicker from "../../Components/ui/ValidatedDatePicker";
import BadgeIcon from "@mui/icons-material/Badge";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CustomeBox from "../../Components/ui/CustomeBox";
import Controls from "../../Components/ui/Controls/Controls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
// import AddIcon from '@mui/icons-material/Add';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import Swal from "sweetalert2";

const TimeSheet = () => {
  const inputEl = useRef(null);
  //list of wbs
  const [wbsMaster, setWbsMaster] = useState([]);

  //DATE pICKER DATE
  const [startDate, setStartDate] = useState(new Date());

  //formatted Date
  const [selectedDate, setSelectedDate] = useState("");

  //details of data if already present
  const [existingDataForSelectedDate, setExistingDataForSelectedDate] =
    useState([]);

  //selected wbs by user
  const [wbsList, setWbsList] = useState([]);

  // table to add new data
  const [showEmptyTable, setShowEmptyTable] = useState(false);

  // table to display existing data , edit or delete
  const [showDataTable, setShowDataTable] = useState(false);

  const [dates, setDates] = useState([]);
  const history = useHistory();

  const [arrayToAddRow, setArrayToAddRow] = useState([]);
  const [number, setNumber] = useState(0);
  const [triggerNumber, setTriggerNumber] = useState(false);

  // console.log( "wbsMaster =>>> " , wbsMaster,
  //   "wbsList ===??? " , wbsList ,
  //   "setWbsList ==>> " , setWbsList
  //   )

  // console.log(arrayToAddRow);

  const functionToAddNumber = (num) => {
    setArrayToAddRow(arrayToAddRow.concat(num))
  }

  const functionToSubNumber = (num) => {
    const data = arrayToAddRow.filter((id) => id !== num);
    setArrayToAddRow(data);
  }

  useEffect( () => {
       if(triggerNumber) functionToAddNumber(number)
  } , [number] )

  const subtractRowNumber = () => {
    if (number > 0) {
      setTriggerNumber(false);
      console.log("number ==>  ", number)
      if (wbsList.length !== 0) {
        console.log("num - 1 ==>>> ", number)
        console.log(wbsList)
        const data = wbsList.filter((id, index) => { if(index !== number);  } )
        console.log("data ===>  ", data);
        setWbsList(data);
      }
      setNumber(number - 1);
      functionToSubNumber(number);
    } else {
      setTriggerNumber(false);
    }
  };

  const getApiForDate = () => {
    axios
      .get(`/checktimesheetDate`)
      .then((res) => {
        if (res.data.status === true) {
          setDates(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getApiForDate();
    const getWBS = async () => {
      try {
        if (wbsMaster.length === 0) {
          const response = await axios.get("/getWbs");
          if (response.data.status === false) {
          } else {
            setWbsMaster(response.data.data);
          }
        }
      } catch (err) {
        toast.error(err, Toaster);
      }
    };
    getWBS();
    const date = new Date();
    const pad = "00";
    const yy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();
    const dateChecker = `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`;
    setSelectedDate(`${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`);
    checkDateHandler(dateChecker);
  }, []);

  const createTimeSheetHandler = async (e) => {
    e.preventDefault();
    if (startDate === null) {
      toast.error("Please select Date", Toaster);
    } else if (wbsList.length === 0) {
      toast.error("Please fill the wbs table", Toaster);
    } else {
      let total = 0;
      if (wbsList.length === 1) {
        if (wbsList[0].wbs_percentage === 100) {
          total = 100;
        }
      } else {
        total = wbsList.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.wbs_percentage;
        }, 0);
      }

      const empTimeSheet = {
        emp_id: localStorage.getItem("empId"),
        timesheet_date: selectedDate,
        details: wbsList,
      };

      try {
        if (total === 100) {
          const response = await axios.post(`/createTimesheet`, empTimeSheet);
          if (response.data.status === true) {
            setArrayToAddRow([])
            getApiForDate();
            // toast.success(response.data.message, Toaster);
            setWbsList([]);
            setShowEmptyTable(false);
            setShowDataTable(false);
            // setSelectedDate(null); 
            checkDateHandler(selectedDate);
            setNumber(0);
            setTriggerNumber(false);
            setArrayToAddRow([]);
            Swal.fire({
              icon: "success",
              html: `<div>
              ${response?.data?.message}
              </div>`,
              timer: 2800,
              showConfirmButton: true
            })
            history.push("/timeSheet");
            getApiForDate();
          } else if (response.data.status === false) {
            setWbsList([]);
            setArrayToAddRow([]);
            Swal.fire({
              icon: "error",
              html: `<div>
              ${response?.data?.message}
              </div>`,
              color: 'rgb(242, 150, 52)',
              background: '#343A40',
              timer: 2800,
              showConfirmButton: true
            })
            // toast.error(response.data.message);
          }
        } else {
          Swal.fire({
            icon: "error",
            timer: 2800,
            showConfirmButton: true,
            title: 'The Total Percentage Must Be 100',
            color: 'rgb(242, 150, 52)',
            background: '#343A40'
          })
          // toast.error("The total Percentage should be equal to 100", Toaster);
        }
      } catch {
        setNumber(0);
        setTriggerNumber(false);
        setArrayToAddRow([]);
        Swal.fire({
          icon: "error",
          timer: 2800,
          showConfirmButton: true,
          title: 'error in timesheet',
          color: 'rgb(242, 150, 52)',
          background: '#343A40'
        })
      }  
    }
  };

  const cancelHandler = () => {
    // setShowEmptyTable(false);
    // setStartDate(null);
    // setSelectedDate(null);
    setWbsList([]);
    setArrayToAddRow([]);
    // setShowDataTable(false);
    // history.push("/timeSheet");
  };

  const formatDate = (date) => {
    console.log(date);
    // const pad = "00";
    // const yy = date.getFullYear().toString();
    // const mm = (date.getMonth() + 1).toString();
    // const dd = date.getDate().toString();
    // const dateChecker = `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`;
    // setSelectedDate(`${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`);
    // checkDateHandler(dateChecker);
    setSelectedDate(date);
    checkDateHandler(date);
    setStartDate(date);
    setWbsList([]);
    setArrayToAddRow([]);
    setArrayToAddRow([]);
    setNumber(0);
    setTriggerNumber(false);
  };

  const checkDateHandler = async (dateChecker) => {
    setExistingDataForSelectedDate([]);
    setShowEmptyTable(false);
    setShowDataTable(false);
    try {
      const response = await axios.post("/checkDate", {
        timesheet_date: dateChecker,
      });
      if (response.data.status === false && response.status === 200) {
        setShowEmptyTable(true);
      } else {
        setExistingDataForSelectedDate(response.data.data.details);
        setShowDataTable(true);
      }
    } catch (err) {
      toast.error(err, Toaster);
    }
  };

  const handleData = () => {};

  return (
    <>
      <ToastContainer />
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card
              card_title="Employee Id "
              card_content={localStorage.getItem("empId")}
              backgroundColor="#17a2b8"
              color="#148a9d"
              icon={<BadgeIcon sx={{fontSize:"50px"}}/>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              card_title="Employee Name "
              card_content={localStorage.getItem("empName")}
              backgroundColor="#28a745"
              color="#228e3b"
              icon={<PersonOutlineIcon sx={{fontSize:"50px"}}/>}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              card_title="Employee Email "
              card_content={localStorage.getItem("empEmail")}
              backgroundColor="#dc3545"
              color="#bb2d3b"
              icon={<MailOutlineIcon sx={{fontSize:"50px"}}/>}
            />
          </Grid>
        </Grid>
      </Box>
       
      <CustomeBox BoxHeader="Time Sheet">
      <div style={{margin:"0px 0px 0px 0px" , color:"black" , fontWeight:"bold" }}>Select Date to Fill Your TimeSheet:</div>
        <Box>   
          {/* <Grid container spacing={3}> */}
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "left",
                justifyContent: "center",
              }}
            >
              {dates.map((v) => {
                return (
                  <Button
                    variant="contained"
                    style={{
                      background: v.status === "Active" ? "#d6e9c6" : "#f2dede",
                      color: v.status === "Active" ? "green" : "red",
                      borderRadius: "5px",
                      margin: "10px 5px 2px 5px",
                      display: "flex",
                      padding: "2px 5px",
                      // maxWidth: "130px",
                      textAlign: "center",
                      cursor:"pointer",
                      fontWeight:"500"
                    }}
                    onClick={() => formatDate(v.date)}
                  >
                    {v.date}{" "}{"("}{moment(v.date , "DD-MM-YYYY").format('ddd')}{")"}  
                  </Button>
                );
              })}
            </div>
            {/* <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}> */}
              {/* <ValidatorForm
                ref={inputEl}
                onSubmit={handleData}
                onError={(errors) => console.log(errors)}
                style={{ paddingTop: "0.7rem", textAlign: "center" }}
              >
                <div
                  style={{
                    display: "block",
                    marginBottom: "0.5rem",
                    paddingLeft: "2.5rem",
                  }}
                >
                  <lable className="labelStyles">
                    Select Date to Fill Your TimeSheet{" "}
                  </lable>
                  <span style={{ color: "red" }}>*</span>:
                </div>

                <Grid>
                   <ValidatedDatePicker
                    value={startDate}
                    minDateForDatepicker={subDays(new Date(), 15)}
                    maxDateForDatepicker={addDays(new Date(), 15)}
                    onChange={(date) => {
                      formatDate(date);
                      setStartDate(date);
                      setWbsList([]);
                      setArrayToAddRow([]);
                      // setSelectedDate(date)
                    }}
                  /> 
                </Grid>
              </ValidatorForm> */}
            {/* </Grid>
            <Grid item xs={12} md={3} /> */}
          {/* </Grid> */}
        </Box>

        {showEmptyTable ? (
          <>
        
            <div
              style={{
                display:"flex",
                justifyContent:"space-between",
                margin: "1rem 0 0 0",
                // backgroundColor: "#3d444ad9",
                background: "rgb(52, 58, 64)",
                color: "#fff",
                padding: "3px",
                // borderRadius: "0.2rem",
                // borderLeft: "7px solid #f79626",
                borderBottom: "2px solid #f79626",
                borderLeft: "2px solid #f79626",
                borderRight: "2px solid #f79626",
              }}
            >
              New Time Sheet
              <div style={{display:"inline" }}>
              Date: {selectedDate}
              </div>
            </div>
            {/* <p style={{ color: "rgb(52, 58, 64)" , fontWeight:"bold", marginTop: "1em" }}>
            If you are working on multiple projects, the overall percentage must equal 100.
            </p> */}
            <div className="tableStyles">
            <Paper elevation={3} style={{width:"100%"}}>
              <TableContainer>
                <Table style={{ width: "100%" }}>
                  <TableHead
                    style={{
                      background: "#c2c7d0",
                      borderRadius: "3px 3px 0 0",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          padding: "10px",
                        }}
                      >
                        Select Project Name (WBS)
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          padding: "10px",
                        }}
                      >
                        WBS Description
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          padding: "10px",
                        }}
                      >
                        Percentage
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color: "#000",
                          fontWeight: "600",
                          padding: "10px",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  
                  <CustomTableBody
                    wbsMaster={wbsMaster}
                    wbsList={wbsList}
                    setWbsList={setWbsList}
                  />

                  {arrayToAddRow.map(v => {
                     return (
                    <CustomTableBody key={v}
                    wbsMaster={wbsMaster}
                    wbsList={wbsList}
                    setWbsList={setWbsList}
                  />
                     )
                  })}
                </Table>
                <div className="icons">
                  <div
                    onClick={() => {
                      if(number < 3) {
                        setNumber(number + 1);
                        setTriggerNumber(true);
                      }
                    }}
                    className="iconsStyle"
                  >
                    <AddIcon fontSize="medium"/>
                  </div>
                  <div
                  className="iconsStyle"
                  onClick={subtractRowNumber}
                  >
                    <RemoveIcon fontSize="medium"/>
                  </div>
                </div>
                <div className="btnTimeSheet">
                  <Controls.Button
                    style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                    onClick={createTimeSheetHandler}
                    text="save"
                  />
                  <Controls.ButtonV2
                    style={{ marginLeft: "1rem" }}
                    onClick={cancelHandler}
                    text="cancel"
                  />
                </div>{" "}
              </TableContainer>
              </Paper>
            </div>
         
          </>
        ) : (
          ""
        )}

        {showDataTable ? (
          <>
            <div
              style={{
                display:"flex",
                justifyContent:"space-between",
                margin: "3rem 0 0 0",
                // backgroundColor: "#3d444ad9",
                background: "rgb(52, 58, 64)",
                color: "#fff",
                padding: "3px",
                // borderRadius: "0.2rem",
                // borderLeft: "7px solid #f79626",
                borderBottom: "2px solid #f79626",
                borderLeft: "2px solid #f79626",
                borderRight: "2px solid #f79626",
              }}
            >
              Update Time Sheet
              <div style={{display:"inline"}}>
              Date: {selectedDate}
              </div>
         
            </div>
            {/* <p style={{ color: "red" , fontWeight:"bold", marginTop: "1em" }}>
            If you are working on multiple projects, the overall percentage must equal 100.
           
            </p> */}
            <div className="tableStyles">
              <ExistingDataTableBody
                existingDataForSelectedDate={existingDataForSelectedDate}
                wbsMaster={wbsMaster}
                selectedDate={startDate}
                setShowEmptyTable={setShowEmptyTable}
                setShowDataTable={setShowDataTable}
                setExistingDataForSelectedDate={setExistingDataForSelectedDate}
                setSelectedDate={setSelectedDate}
                setStartDate={setStartDate}
                callinggetApi={checkDateHandler}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </CustomeBox>
    </>
  );
};

export default TimeSheet;
