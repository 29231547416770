import React, { useState, useEffect } from "react";
import DataTable from "../../Components/ui/dataTable";
import axios from "../../Axios";
import CustomeBox from "../../Components/ui/CustomeBox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import ApprovePopup from "../../Components/PopUp/ApprovePopup";
import ShowLeave from "./ShowLeave";

const headCells = [
  { id: "emp_id", label: "Emp Id" },
  { id: "emp_name", label: "Name" },
  { id: "emp_email", label: "Email" },
  { id: "from_date", label: "From Date" },
  { id: "to_date", label: "To Date" },
  { id: "leave_type", label: "Leave Type" },
  { id:"leave_days" , label: "Day Count"},
  { id: "action", label: "Action" },
];

const LeaveApprovalTb = () => {
  const [leaveApproval, setLeaveApproval] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [data, setData] = useState([]);

  const [dataFound, setDataFound] = useState(true);

  const [resonForReject , setResonForReject] = useState("");

  const [triggerLeaveHistoryModel , setTriggerLeaveHistoryModel] = useState(false);
  const [itemLeaveHistory , setitemLeaveHistory ] = useState(null);
  
  const [ triggerErrorMessageForInputField , setTriggerErrorMessageForInputField] = useState(false);
  const handleResonForRejectionField = (e) => {
    setTriggerErrorMessageForInputField(false);
    setResonForReject(e.target.value);
  }

  const getLeaveApproval = () => {
    try {
      const LeaveApprovalList = async () => {
        const response = await axios.get("/getLeaveApplied");
        if(response?.data?.status === false) {
          toast.error(`${response.data.message}`);
          //  alert(response.data.message)
           setDataFound(false);
           setLeaveApproval([]);
        } else  if (response?.data?.data?.length === 0) {
          setDataFound(false);
          setLeaveApproval([]);
        } else {
          setDataFound(true);
          setLeaveApproval(response.data.data);
          setFilteredRows(response.data.data);
        }
      };
      LeaveApprovalList();
    } catch (err) {
      setDataFound(false);
      setLeaveApproval([]);
      toast.error(err);
    }
  };

  useEffect(() => {
    getLeaveApproval();
  }, []);

  const approveLeaveHandler = async () => {
    try {
      const response = await axios.post("/approveleave", {
        emp_id: data.emp_id,
        _id: data._id,
      });
      if (response.data.status === true) {
        toast.success(response.data.message, Toaster);
        setPopUpOpen(false);
        getLeaveApproval();
      } else {
        setPopUpOpen(false);
        toast.error(response.data.message, Toaster);
      }
    } catch (err) {
      setPopUpOpen(false);
      toast.error(err, Toaster);
    }
  };

  const rejectLeaveHandler = async () => {
    if(resonForReject !== "") {
      try {
        const response = await axios.post("/rejectleave", {
          emp_id: data.emp_id,
          _id: data._id,
          reson:resonForReject
        });
        if (response.data.status === true) {
          toast.success(response.data.message, Toaster);
          setPopUpOpen(false);
          getLeaveApproval();
        } else {
          toast.error(response.data.message, Toaster);
          setPopUpOpen(false);
        }
      } catch (err) {
        toast.error(err, Toaster);
        setPopUpOpen(false);
      }
    } else {
      setTriggerErrorMessageForInputField(true);
    }
   
  };

  const handleSearch = (e) => {
    let search = e.target.value;
    let data = filteredRows.filter((v) => {
      return (
        String(v.emp_name).toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });
    setLeaveApproval(data);
  };

  const approveHandler = (item) => {
    setRejected(false);
    setData(item);
    setApproved(true);
    setPopUpOpen(true);
  };

  const rejectHandler = (item) => {
    setApproved(false);
    setData(item);
    setRejected(true);
    setPopUpOpen(true);
  };

  const handleShowButton = (item) => {
    setTriggerLeaveHistoryModel(true);
    setitemLeaveHistory(item);
  }

  const handleClose = () => {
    setTriggerLeaveHistoryModel(false);
    setitemLeaveHistory(null);
  }

  return (
    <>
      <ToastContainer />
      <div>
        <CustomeBox BoxHeader="Leave Approval Details">
          <input
            type="text"
            onChange={handleSearch}
            placeholder="Search by name.."
            style={{
              border: "1px solid #343A40",
              borderRadius: "0.2rem",
              padding: "0.50rem",
              backgroundColor: "transparent",
              width: "30%",
              marginBottom: "20px",
            }}
          />
          <DataTable
            records={leaveApproval}
            headCells={headCells}
            enableCustomButton={true}
            enableShowButton={true} //harsh
            showOnclickFn={handleShowButton}
            customButtonOnclickFn={approveHandler}
            enableDeleteButton={true}
            deleteOnclickFn={rejectHandler}
            dataFound={dataFound}
          />
        </CustomeBox>
      </div>
      {approved && (
        <ApprovePopup
          approveFunc={approveLeaveHandler}
          popUpOpen={popUpOpen}
          setPopUpOpen={setPopUpOpen}
          header="Are you sure you want approve ?"
        />
      )}
      {rejected && (
        <ApprovePopup
          approveFunc={rejectLeaveHandler}
          popUpOpen={popUpOpen}
          setPopUpOpen={setPopUpOpen}
          showInputField={true}
          inputFieldForRejection = {handleResonForRejectionField}
          valueForInputField = {resonForReject}
          inputFieldErrorMessageTrueOrFalse = {triggerErrorMessageForInputField}
          header="Are you sure you want reject ?"
        />
      )}
      {triggerLeaveHistoryModel && (
         <ShowLeave
           item = {itemLeaveHistory}
           open = {triggerLeaveHistoryModel}
           close = {handleClose}
         /> 
      )}
    </>
  );
};

export default LeaveApprovalTb;
