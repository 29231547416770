import React from "react";

import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import "./login.css";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "../../Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import bmLogo from "../../assets/botmantra-logo-removebg1.png";
import CircularProgress from '@mui/material/CircularProgress';

const MyLoginPage = (props) => {
  const { setName } = props;
  let history = useHistory();
  const [user_email_id, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [Forgotpassword, setForgotpassword] = React.useState(false);
  const [Message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [triggerLoader , setTriggerLoader] = React.useState(false);

  // onchange function for email and password
  const onChangeHandler = (event) => {
    setStatus("");
    setMessage("");
    event.preventDefault();
    if (event.target.name === "user_email_id") {
      setUsername(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(`/getEmployeeDetails`);
      if (response.data.status === false) {
        toast.error(response.data.message, Toaster);
      } else {
        setName(response.data.data.emp_name);
        localStorage.setItem("empId", response.data.data.emp_id);
        localStorage.setItem("empEmail", response.data.data.emp_email);
        localStorage.setItem("empName", response.data.data.emp_name);
      }
    } catch (err) {
      toast.error(err, Toaster);
    }
  };

  // onSubmit function
  const submitHandler = () => {
    if (Forgotpassword) {
      setTriggerLoader(true);
      axios
        .post("/resetPassword", {
          url: `${window.location.origin}/ResetPassword/${user_email_id}` ,
          user_email: user_email_id 
        })
        .then((res) => {
          setTriggerLoader(false);
          setStatus(res.data.status);
          setMessage(res.data.message);
        })
        .catch((error) => {
          setTriggerLoader(false);
          setStatus(error.response.data.status);
          setMessage(error.response.data.message);
        });
    } else {
      setTriggerLoader(true);
      axios
        .post("/login", { email: user_email_id, password: password })
        .then((res) => {
          if(res.data.log_status === true) {
          setTriggerLoader(false);
          localStorage.clear();
          const data = res.data.access_token;
          const roleStatus = res.data.status;
          localStorage.setItem("accessToken", data);
          localStorage.setItem("status", roleStatus);
          fetchEmployeeData();
          if (roleStatus === "Admin") {
            history.push("/leaveApproval");
          } else {
            history.push("/timeSheet");
          }
          } else {
            setTriggerLoader(false);
            localStorage.clear();
            setStatus(res.data.status);
            setMessage(res.data.message);
          }
        })
        .catch((error) => {
          setTriggerLoader(false);
          setStatus(error.response.data.status);
          setMessage(error.response.data.message);
        });
    }
  };

  // route function for registation page
  // const toRegistationPage = () => {
  //   history.push("/facilityForm");
  // };

  const fogotPasswordHandler = () => {
    setForgotpassword(true);
  };

  const justLogin = () => {
    setForgotpassword(false);
    setStatus("");
    setMessage("");
    setUsername("");
  };

  // const knowMoreButtonHandler = () => {
  //   alert("Sorry! Not Implemented.");
  // };

  return (
    <>
      <ToastContainer />
      <div id="container">
        <div className="main-section">
          {/* left section */}
          <div className="left-section">
            <center>
              <img src={bmLogo} alt="botmantra logo" height="100" width="200" />
            </center>

            <div className="footer">
              
            &#xA9; All Rights Reserved. Bot Mantra - {new Date().getFullYear()}.                
              {/* &#xA9; Copyright Botmantra  */}
            </div>
          </div>

          {/* right section */}
          <div className="right-section">
            <div className="login-form">
              {Forgotpassword ? (
                ""
              ) : (
                <div className="title">
                  <center>
                    <h3>Login here!</h3>
                  </center>
                </div>
              )}

              {Forgotpassword ? (
                <div>
                  <center style={{ fontSize: "24px" }}>
                    <h3>Reset your password</h3>
                  </center>
                  <center style={{ fontSize: "16px" }}>
                    Enter your user account's verified email address and we will
                    send you a temporary password.
                  </center>
                </div>
              ) : (
                <center style={{ fontSize: "16px" }}>
                  Please enter your login details.
                </center>
              )}

              <div className="form-body">
                <ValidatorForm
                  onSubmit={submitHandler}
                  onError={(errors) => console.log(errors)}
                >
                  <TextValidator
                    margin="dense"
                    fullWidth={true}
                    label="Email Id"
                    name="user_email_id"
                    value={user_email_id}
                    variant="standard"
                    size="small"
                    validators={["required"]}
                    errorMessages={["Please enter the Email id."]}
                    onChange={onChangeHandler}
                  />
                  {Forgotpassword ? (
                    ""
                  ) : (
                    <TextValidator
                      margin="dense"
                      fullWidth={true}
                      label="Password"
                      name="password"
                      variant="standard"
                      size="small"
                      type="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={["Please enter the password."]}
                      onChange={onChangeHandler}
                    />
                  )}

                  <Button
                    style={{ marginTop: "15px", background: "#FA9620" }}
                    type="submit"
                    margin="dense"
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                  >
                    { triggerLoader ? 
                    <div className="Loader"> <CircularProgress style={{color:"white" , alignItems:"center"}} disableShrink size={20}/> </div>
                     : Forgotpassword ? 
                     "Reset Password" : 
                     "Login"  }
                    {/*{Forgotpassword ? "Reset Password" : "Login"} */}
                  </Button>
                  {/* {Message?<DoneIcon style={{color:"green"}}/>:""} */}
                  {status ? (
                    <center style={{ color: "green", fontSize: "14px" , marginTop:"10px" }}>
                      {Message}
                    </center>
                  ) : (
                    <center style={{ color: "red" , marginTop:"10px" }}>{Message}</center>
                  )}

                  {/* forgot password button */}
                  <div
                    className="forgot-password"
                    style={{ marginTop: "10px" }}
                  >
                    {Forgotpassword ? (
                      <span style={{ cursor: "pointer" }} onClick={justLogin}>
                        Just Login
                      </span>
                    ) : (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={fogotPasswordHandler}
                      >
                        Forgot Password?
                      </span>
                    )}
                  </div>
                </ValidatorForm>
                {/* know more section */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyLoginPage;
