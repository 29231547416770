import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CustomeModal from "../../Components/ui/CustomeModal";
import axios from "../../Axios";
import Controls from "../../Components/ui/Controls/Controls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@material-ui/core/Select";

const EditProject = ({
  isEdit,
  setIsEdit,
  editData,
  setStatus,
  status,
  getProjectList,
}) => {
  const [error, setError] = React.useState(false);

  const [editFormData, setEditFormData] = useState({
    wbs_code: editData.wbs_code,
    wbs_description: editData.wbs_description,
    wbs_status: editData.wbs_status,
    _id: editData._id,
  });

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  useEffect(() => {
    setStatus(editFormData.wbs_status);
  }, [setStatus, editFormData.wbs_status]);

  const handleEditFormSubmit = async () => {
    if (editFormData.wbs_code === "" || editFormData.wbs_description === "") {
      setError(true);
      setIsEdit(true);
    } else {
      editFormData.wbs_status = status;
      try {
        const response = await axios.post("/updateWbs", editFormData);
        if (response.data.status === true) {
          toast.success("Successfully Updated Project", Toaster);
          getProjectList();
        } else if (response.data.status === false) {
          toast.error(response.data.message, Toaster);
        }
        setError(false);
        setIsEdit(false);
      } catch (err) {
        toast.error(err, Toaster);
      }
    }
  };

  const handleSucess = (e) => {
    setIsEdit(false);
    handleEditFormSubmit(e);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        open={isEdit}
        onClose={handleCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomeModal BoxHeader="Edit Project">
          <form>
            {error && (
              <p style={{ color: "red" }}>
                Please fill all the mandatory fields
              </p>
            )}
            <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
              <Grid item xs={2}>
                <label>Wbs Code </label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="text"
                  id="wbs_code"
                  name="wbs_code"
                  value={editFormData.wbs_code}
                  onChange={handleEditFormChange}
                />
              </Grid>

              <Grid item xs={2}>
                <label>Wbs Description</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                    padding: "10px",
                  }}
                  type="text"
                  id="wbs_description"
                  name="wbs_description"
                  value={editFormData.wbs_description}
                  onChange={handleEditFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Status</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: "90%" }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label=""
                    variant="outlined"
                    name="wbs_status"
                    value={status}
                    // value={editFormData.emp_status == 'A' ? "Active" : "In-active"}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="In-Active">In-Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ textAlign: "right", marginTop: "3rem" }}>
              <Controls.Button
                style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                onClick={handleSucess}
                text="save"
              />

              <Controls.ButtonV2
                style={{ marginLeft: "1rem" }}
                onClick={handleCancel}
                text="cancel"
              />
            </div>
          </form>
        </CustomeModal>
      </Modal>
    </div>
  );
};

export default EditProject;
