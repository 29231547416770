import React, { useState, useRef, useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidatedDatePicker from "../../Components/ui/ValidatedDatePicker";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import CustomeModal from "../../Components/ui/CustomeModal";
import axios from "../../Axios";
import Controls from "../../Components/ui/Controls/Controls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@mui/material/IconButton";

const EditEmp = ({
  setIsEdit,
  isEdit,
  editData,
  // setDOB,
  // setDOE,
  // setDOJ,
  // doe,
  // dob,
  // doj,
  setStatus,
  status,
  getEmpDetails,
}) => {
  const inputEl = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [displayDateForDob, setDisplayDateForDob] = useState("");
  const [displayDateForDoj, setDisplayDateForDoj] = useState("");
  const [displayDateForDoe, setDisplayDateForDoe] = useState("");

  const handleClose = () => setOpen(false);

  // console.log("Edit Data ==>>> " ,editData)

  if (open) {
  }

  const [editFormData, setEditFormData] = useState({
    emp_id: editData.emp_id,
    emp_email: editData.emp_email,
    emp_name: editData.emp_name,
    emp_dob: editData.emp_dob,
    emp_doj: editData.emp_doj,
    emp_doe: editData.emp_doe,
    emp_status: editData.emp_status,
    phone_number:editData["Phone number"],
    telegram_id:editData["Telegram Id"]
  });

  console.log("editFormData ",editFormData)

  const handleDOBHandler = (date) => {
    if (date !== "") {
      const value = date.split("-");
      const joinDateFormate = `${value[2]}-${value[1]}-${value[0]}`;
      setDisplayDateForDob(joinDateFormate);
    } else {
      setDisplayDateForDob("");
    }

    // setDOB(
    //   date
    //     ?.toLocaleString("en-GB")
    //     ?.split(",")[0]
    //     ?.split("/")
    //     ?.reverse()
    //     ?.join("-")
    // );
  };

  const handleDOJHandler = (date) => {
    if (date !== "") {
      const value = date.split("-");
      const joinDateFormate = `${value[2]}-${value[1]}-${value[0]}`;
      setDisplayDateForDoj(joinDateFormate);
    } else {
      setDisplayDateForDoj("");
    }

    // console.log("Handle DOJ Trigger =====>>> ", date);

    // setDOJ(
    //   date
    //     ?.toLocaleString("en-GB")
    //     ?.split(",")[0]
    //     ?.split("/")
    //     ?.reverse()
    //     ?.join("-")
    // );
  };

  const handleDOEHandler = (date) => {
    console.log(date)
    if (date !== "" || date !== "undefined" ) {
      const value = date?.split("-");
      const joinDateFormate = `${value[2]}-${value[1]}-${value[0]}`;
      setDisplayDateForDoe(joinDateFormate);
    } else {
      setDisplayDateForDoe("");
    }

    // setDOE(
    //   date
    //     ?.toLocaleString("en-GB")
    //     ?.split(",")[0]
    //     ?.split("/")
    //     ?.reverse()
    //     ?.join("-")
    // );
  };

  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  console.log(editFormData)

  const handleDateChange = (date) => {
    const { e, name } = date;
    const pad = "00";
    const yy = e.getFullYear().toString();
    const mm = (e.getMonth() + 1).toString();
    const dd = e.getDate().toString();

    let fieldValue = `${(pad + dd).slice(-2)}-${(pad + mm).slice(-2)}-${yy}`;

    if (name === "emp_dob") {
      handleDOBHandler(fieldValue);
      const newFormData = { ...editFormData };
      newFormData[name] = fieldValue;
      setEditFormData(newFormData);
    } else if (name === "emp_doj") {
      handleDOJHandler(fieldValue);
      const newFormData = { ...editFormData };
      newFormData[name] = fieldValue;
      setEditFormData(newFormData);
    } else {
      handleDOEHandler(fieldValue);
      const newFormData = { ...editFormData };
      newFormData[name] = fieldValue;
      setEditFormData(newFormData);
    }
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        editFormData.emp_name === "" ||
        editFormData.emp_email === "" ||
        editFormData.emp_id === "" ||
        editFormData.emp_status === "" ||
        editFormData.emp_dob === "" ||
        editFormData.emp_doj === "" ||
        editFormData.phone_number === "" ||
        editFormData.telegram_id === ""
      ) {
        setError(true);
        setIsEdit(true);
      } else {
        // editFormData.emp_dob = dob;
        // editFormData.emp_doj = doj;
        // editFormData.emp_doe = doe;
        editFormData.emp_status = status;

        const response = await axios.post("/updateEmpDetails", editFormData);
        if (response.data.status === true) {
          toast.success(response.data.message, Toaster);
          getEmpDetails();
        } else if (response.data.data.status === false) {
          toast.error(response.data.message, Toaster);
        }

        // setDOE("");
        // setDOJ("");
        // setDOB("");
        setError(false);
        setIsEdit(false);
      }
    } catch (err) {
      toast.error(err, Toaster);
    }
  };

  const handle = () => {};

  const handleSucess = (e) => {
    setIsEdit(false);
    handleEditFormSubmit(e);
  };

  useEffect(() => {
    handleDOBHandler(editData.emp_dob);
    handleDOEHandler(editData.emp_doe);
    handleDOJHandler(editData.emp_doj);
    setStatus(editFormData.emp_status);
  }, [
    editData.emp_dob,
    editData.emp_doe,
    editData.emp_doj,
    editFormData.emp_status,
    setStatus,
  ]);

  const handleCancel = () => {
    // setDOE("");
    // setDOJ("");
    // setDOB("");
    setIsEdit(false);
  };

  const handleClearDataForDOEDate = () => {
    setEditFormData({ ...editFormData, emp_doe: "" });
    setDisplayDateForDoe("");
  };

  return (
    <div>
      <ToastContainer />
      <Modal
        open={isEdit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomeModal BoxHeader="Edit Employee">
          <ValidatorForm
            ref={inputEl}
            onSubmit={handle}
            onError={(errors) => console.log(errors)}
          >
            {error && (
              <p style={{ color: "red" }}>
                Please fill all the mandatory fields
              </p>
            )}
            <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
              <Grid item xs={2}>
                <label>Employee Id</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="number"
                  id="empId"
                  name="emp_id"
                  value={editFormData.emp_id}
                  onChange={handleEditFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <lable className="labelStyles">DOB </lable>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={3.5}>
                <ValidatedDatePicker
                  style={{ marginLeft: "2rem" }}
                  value={displayDateForDob ?? ""}
                  onChange={(date) => {
                    // handleDOBHandler(date);
                    handleDateChange({ e: date, name: "emp_dob" });
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Employee Email</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="empEmail"
                  name="emp_email"
                  value={editFormData.emp_email}
                  onChange={handleEditFormChange}
                />
              </Grid>

              <Grid item xs={2}>
                {" "}
                <lable className="labelStyles">DOJ</lable>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={3.5}>
                <ValidatedDatePicker
                  style={{ marginLeft: "0.1rem" }}
                  value={displayDateForDoj ?? ""}
                  onChange={(date) => {
                    handleDateChange({ e: date, name: "emp_doj" });
                    // handleDOJHandler(date);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Employee Name</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="empName"
                  name="emp_name"
                  value={editFormData.emp_name}
                  onChange={handleEditFormChange}
                />
              </Grid>
              <Grid item xs={2}>
                <lable className="labelStyles">DOE</lable>
              </Grid>
              <Grid item xs={3.5}>
                <ValidatedDatePicker
                  value={displayDateForDoe ?? ""}
                  onChange={(date) => {
                    handleDateChange({ e: date, name: "emp_doe" });
                    // handleDOEHandler(date);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={0.5}
                style={{
                  padding: "0px",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <IconButton size="small">
                  <div
                    onClick={handleClearDataForDOEDate}
                    style={{
                      fontSize: "20px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    X
                  </div>
                </IconButton>
              </Grid>

              {/* Edit */}
              <Grid item xs={2}>
                <label>Mobile No</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <input
                  style={{
                    width: "90%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  value={editFormData.phone_number}
                  onChange={handleEditFormChange}
                />
              </Grid>

              <Grid item xs={2}>
                <lable className="labelStyles">Telegram ID</lable>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={3.5}>
              <input
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid #9e9e9e6b",
                    borderRadius: "0.2rem",
                  }}
                  type="text"
                  id="telegram_id"
                  name="telegram_id"
                  value={editFormData.telegram_id}
                  onChange={handleEditFormChange}
                />
              </Grid>

              <Grid item xs={2}>
                <label>Status</label>
                <span style={{ color: "red" }}>*</span>:
              </Grid>
              <Grid item xs={4}>
                <FormControl style={{ width: "90%" }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label=""
                    variant="outlined"
                    name="emp_status"
                    value={status}
                    // value={editFormData.emp_status == 'A' ? "Active" : "In-active"}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="In-Active">In-Active</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div style={{ textAlign: "right", marginTop: "3rem" }}>
              <Controls.Button
                style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                onClick={handleSucess}
                text="save"
              />

              <Controls.ButtonV2
                style={{ marginLeft: "1rem" }}
                onClick={handleCancel}
                text="cancel"
              />
            </div>
          </ValidatorForm>
        </CustomeModal>
      </Modal>
    </div>
  );
};

export default EditEmp;
