import React, { useEffect, useState } from "react";
import AddNewEmployee from "./AddEmp";
import EditEmp from "./EditEmp";
import DataTable from "../../Components/ui/dataTable";
import axios from "../../Axios";
import CustomeBox from "../../Components/ui/CustomeBox";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";


const headCells = [
  { id: "emp_id", label: "Emp Id" },
  { id: "emp_name", label: "Name", enableSorting: true },
  { id: "emp_email", label: "Email" },
  { id: "emp_dob", label: "Date of Birth" },
  { id: "emp_doj", label: "Date of Joining" },
  { id: "emp_doe", label: "Date of Exit" },
  { id: "emp_status", label: "Status" },
  { id: "action", label: "Action" },
];

export default function EmployeeList() {
  const [empList, setEmpList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState("");

  const [selectField , setSelectField] = useState("")

  const [open, setOpen] = React.useState(false);

  const [error, setError] = useState(false);

  const [dob, setDOB] = useState("");
  const [doj, setDOJ] = useState("");
  const [doe, setDOE] = useState("");

  const [status, setStatus] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const [dataFound, setDataFound] = useState(true);

  const [newEmpForm, setNewEmpForm] = useState({
    // data entered in the form
    emp_id: "",
    emp_email: "",
    emp_name: "",
    phone_number:"",
    emp_dob: "",
    emp_doj: "",
    emp_doe: "",
    telegram_id:"",
    // emp_status: "",
  });

   const handleSelectfunc = (e) => {
     
    if(e.target.value === "All Employee") {
      setSelectField("All Employee")
      const SortByNumber = filteredRows.sort( (a , b ) =>  {
     return a.emp_id - b.emp_id  }
     )
    setEmpList(SortByNumber)
    } else if(e.target.value === "All Active Employee") {
      setSelectField("All Active Employee")
      const Active = filteredRows.filter( a =>  a.emp_status === "Active")
      const SortByNumberActive = Active.sort( (a , b ) =>  {
        return a.emp_id - b.emp_id  }
        )
      setEmpList(SortByNumberActive)
    } else {
      setSelectField("All In-Active Employee")
    const InActive = filteredRows.filter( a => a.emp_status === "In-Active" )
    const SortByNumberInActive = InActive.sort( (a , b ) =>  {
      return a.emp_id - b.emp_id  }
      )
    setEmpList(SortByNumberInActive)
    }
    // console.log(data);

    // const Active = data.filter( a =>  a.emp_status === "Active")
    // const InActive = data.filter( a => a.emp_status === "In-Active" )

     
    // const SortByNumber = data.sort( (a , b ) =>  {
    //  return a.emp_id - b.emp_id  }
    //  )

    //  const SortByNumberInActive = InActive.sort( (a , b ) =>  {
    //   return a.emp_id - b.emp_id  }
    //   )

      // console.log("SortByNumber => ", SortByNumber);
      // console.log("SortByNumberInActive => " , SortByNumberInActive);
     
    // setEmpList([ ...SortByNumber ])
   }

  const getEmpDetails = () => {
    try {
      const EmployeeList = async () => {
        const response = await axios.get("/getEmployeeAll");
        if(response?.data?.status === false) {
          toast.error(`${response?.data?.message}`, Toaster);
          setDataFound(false);
        } else if (response?.data?.data?.length === 0) {
          setDataFound(false);
        } else {
          setDataFound(true);
          // fincToSort(response.data.data)
          setEmpList(response.data.data);
          setFilteredRows(response.data.data);
        }
      };
      EmployeeList();
    } catch (err) {
      setDataFound(false);
      toast.error(err, Toaster);
    }
  };


  
  useEffect(() => {
    getEmpDetails();
  }, []);

  const handleAddFormChange = (e) => {
    setError(false);
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const newFormData = { ...newEmpForm };
    newFormData[fieldName] = fieldValue;
    // newFormData.emp_status= status;
    newFormData.emp_doj = doj;
    newFormData.emp_dob = dob;
    newFormData.emp_doe = doe;

    setNewEmpForm(newFormData);
  };

  const handleAddFormSubmit = async (e) => {
    console.log(newEmpForm);
    e.preventDefault();
    if (
      newEmpForm.emp_name === "" ||
      newEmpForm.emp_email === "" ||
      newEmpForm.emp_id === "" ||
      newEmpForm.phone_number === "" ||
      newEmpForm.telegram_id === "" ||
      dob === "" ||
      doj === ""
    ) {
      setError(true);
      setOpen(true);
    } else {
      const newItem = {
        emp_id: newEmpForm.emp_id,
        emp_email: newEmpForm.emp_email,
        emp_name: newEmpForm.emp_name,
        phone_number:newEmpForm.phone_number,
        telegram_id:newEmpForm.telegram_id,
        emp_dob: dob.split("-").reverse().join("-"),
        emp_doe: doe.split("-").reverse().join("-"),
        emp_doj: doj.split("-").reverse().join("-"),
      };
      const response = await axios.post("/createEmployee", newItem);
      if (response.data.status === true) {
        toast.success(response.data.message, Toaster);
        getEmpDetails();
      } else if (response.data.data.status === false) {
        toast.error(response.data.message, Toaster);
      }

      setNewEmpForm({
        emp_id: "",
        emp_email: "",
        emp_name: "",
        emp_dob: "",
        emp_doj: "",
        emp_doe: "",
        phone_number:"",
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );

      setDOB("");
      setDOJ("");
      setDOE("");
      // setStatus('');
      setError(false);
      setOpen(false);
    }
  };

  const editHandler = (item) => {
    setIsEdit(true);
    setEditData(item);
  };

  const handleSearch = (e) => {
    let search = e.target.value;

    let data = filteredRows.filter((v) => {
      return (
        String(v.emp_name).toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });

    if (data.length === 0) {
      setDataFound(false);
    } else {
      setDataFound(true);
      setEmpList(data);
    }

  };

  return (
    <>
      {/* toaster */}
      <ToastContainer />

      {/* react data table */}
      <CustomeBox BoxHeader="Employee Master" >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <input
              type="text"
              onChange={handleSearch}
              placeholder="Search by name.."
              style={{
                border: "1px solid #343A40",
                borderRadius: "0.2rem",
                padding: "0.50rem",

                marginTop: "0.7rem",
                marginRight: "2rem",
                width: "40%",
                backgroundColor: "transparent",
              }}
            />
          </Grid>

          <Grid item xs = {2} >
            <div style={{marginTop:"11px"}}>
          <FormControl fullWidth>
          <InputLabel id="demo-select-small">Filter Emp</InputLabel>
          <Select
            // style={{ width: "100%" }}
            labelId="demo-simple-select-label"
            id="demo-select-small"
            value={selectField}
            onChange={handleSelectfunc}
            label="Select WBS"
            size="small"
          >
              <MenuItem value="All Employee">
              All Employee
              </MenuItem>
              <MenuItem value="All Active Employee">
              All Active Employee
              </MenuItem>
              <MenuItem value="All In-Active Employee">
              All In-Active Employee
              </MenuItem>
    
          </Select>
          </FormControl>
          </div>
          </Grid>

          <Grid item xs={2}>
            <AddNewEmployee
              handleAddFormChange={handleAddFormChange}
              handleAddFormSubmit={handleAddFormSubmit}
              setDOB={setDOB}
              setDOE={setDOE}
              setDOJ={setDOJ}
              doe={doe}
              dob={dob}
              doj={doj}
              error={error}
              setError={setError}
              setOpen={setOpen}
              open={open}
            />
          </Grid>
        </Grid>

        {isEdit && (
          <EditEmp
            editData={editData}
            setIsEdit={setIsEdit}
            // setDOB={setDOB}
            // setDOE={setDOE}
            // setDOJ={setDOJ}
            status={status}
            setStatus={setStatus}
            // doe={doe}
            // dob={dob}
            // doj={doj}
            isEdit={isEdit}
            getEmpDetails={getEmpDetails}
          />
        )}

        <DataTable
          records={empList}
          headCells={headCells}
          editOnclickFn={editHandler}
          enableEditButton={true}
          enablePagination={true}
          dataFound={dataFound}
          // deleteOnclickFn={deleteHandler}
          // enableDeleteButton={true}
        />
      </CustomeBox>
    </>
  );
}
