import React, { useState, useRef } from "react";
import CustomeBox from "../../Components/ui/CustomeBox";
import ValidatedDatePicker from "../../Components/ui/ValidatedDatePicker";
import { ValidatorForm } from "react-material-ui-form-validator";
import "./Download_Attendance_sheet.css";
import TextField from "@mui/material/TextField";
import axios from "../../Axios";
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';

export default function Download_Attendance_sheet() {
  const inputEl = useRef(null);

  const [employeeID , setEmployeeID] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [triggerLoaderInButton , setTriggerLoaderInButton ] = useState(false)

  const handleFromDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setFromDate(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
  };

  const handleToDateChange = (v) => {
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setToDate(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
  };


  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleDownloadExcel = () => {
    if(fromDate === null || toDate === null ) {
       alert("From Date and To Date can't be empty")
    } else {
      setTriggerLoaderInButton(true);
      const contentType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const filename = `Leave Details.xlsx`;

      axios.post(`/getAttendanceData` , {
        emp_id:employeeID,
        start_date:moment(fromDate).format("DD-MM-YYYY"),
        end_date:moment(toDate).format("DD-MM-YYYY")
      }).then( res => {
        if(res?.data?.status === true ) {
          downloadBase64File(contentType , res.data.data , filename )
          setTriggerLoaderInButton(false);
          setEmployeeID("");
          setFromDate(null);
          setToDate(null);
        } else {
      setTriggerLoaderInButton(false);
          alert("Unable To Download File Try After SomeTime")
        }
      }).catch( e => {alert("Server Error Try After Some Time"); setTriggerLoaderInButton(false) })
    }
  }

  return (
    <>
      <CustomeBox BoxHeader="Download Attendance Sheet">
        <ValidatorForm
          ref={inputEl}
          // onSubmit={handleDownloadExcel}
          onError={(errors) => console.log(errors)}
        >
          <div className="MainDiv">
            <TextField
              size="small"
              id="outlined-basic"
              value={employeeID}
              label="Enter Employee Id"
              variant="outlined"
              onChange={ (v) => setEmployeeID(v.target.value)}
            />

            <ValidatedDatePicker
              value={fromDate}
              placeholder="From Date"
              // minDateForDatepicker={subDays(new Date(), 11)}
              onChange={(v) => handleFromDateChange(v)}
            />
            <ValidatedDatePicker
              value={toDate}
              placeholder="To Date"
              minDateForDatepicker={fromDate}
              onChange={(v) => handleToDateChange(v)}
            />
            <button className="button-outlined-primary" onClick={handleDownloadExcel}>
             { triggerLoaderInButton ? <div style={{width:"169px"}}> <CircularProgress style={{color:"white" , alignItems:"center"}} disableShrink size={23}/> </div>   : "Download Excel sheet"}
            </button>
          </div>
        </ValidatorForm>
      </CustomeBox>
    </>
  );
}
