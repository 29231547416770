import React from "react";
import './home.css';

function HomePage() {
  
  return (
    <>
      <div className="homePageCards">
        {/* <Card title={"Time Sheet Managment"} link='/timeSheet'/>
        <Card title={"Leave Managment"} link='leaveMgmt'/>
        <Card title={"Holiday Calender"} /> */}
      </div>

      {/* <div className="homePageCards">
        <Card title={"Apply for Work From Home"} />
        <Card title={"Apply for Comp Off"} />
        <Card title={"Create Employee"} />
      </div> */}
    </>
  );
}

export default HomePage;
