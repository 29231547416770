import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import axioss from "../../Axios";
import Swal from "sweetalert2";
import CustomeBox from "../../Components/ui/CustomeBox";
import "./MarkAttendance.css";
import { useHistory } from "react-router-dom";

export default function MarkAttendance() {
  const [workingFrom, setWorkingFrom] = useState("Work From Office");
  const [checkin, setCheckin] = useState("No");
  const [checkout, setCheckout] = useState("No");
  const [ipAddress , setIpAddress ] = useState("");
  const [date , setDate ] = useState("");

  const token = localStorage.getItem("accessToken");

  let history = useHistory();

  const stykeIn = { 
     backgroundColor: "transparent",
     border:"1px solid #ddd",
     color:"#ddd" 
    }

  const mdmChangeHandler = (e) => {
    const { value } = e.target;
    setWorkingFrom(value);
  };

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIpAddress(res.data.IPv4)
    // console.log(res.data);
    // setIP(res.data.IPv4)
  };

  const getApiData = () => {
    axioss.get("/getAttendance").then((res) => {
      if (res.data.status === true) {
        setCheckin(res.data.check_in);
        setCheckout(res.data.check_out);
        setDate(res.data.date);
      }
    }).catch = () => {
      alert("someThing Went Wrong");
    };
  };

  const CheckHandler = (value) => {
    if (workingFrom === "") {
      Swal.fire({
        icon: "error",
        html: `<div>
            "Select Wroking Type!
            </div>`,
        timer: 1200,
        showConfirmButton: false,
      });
    } else {
      axioss
        .post("/postAttendance", {
          emp_id: localStorage.getItem("empId"),
          status: value,
          work_type: workingFrom,
          ip_address:ipAddress
        })
        .then((res) => {
          if (res.data.status === true) {
            Swal.fire({
              icon: "success",
              html: `<div>
                  ${res?.data?.message}
                  </div>`,
              timer: 1200,
              showConfirmButton: false,
            });
            getApiData();
          } else {
          }
        }).catch = () => {
        alert("SomeThing Went Wrong!");
      };
    }
  };

  useEffect(() => {
    getData();
    getApiData();
  }, []);

  const handleDisplayMessage = () => {
    if (checkin === "Yes" && checkout === "Yes") {
      return (
        <h5 style={{ color: "green" }}>Thank you, you have already checked out for {date}</h5>
      );
    }
    if (checkin === "No" && checkout === "No") {
      return <h5 style={{ color: "red" }}>Please check in for {date}</h5>;
    } else if (checkin === "Yes" && checkout === "No") {
      return <h5 style={{ color: "red" }}>Please check out for {date}</h5>;
    }
  };

  return (
    <>
    { token  ? (
      <>
      <CustomeBox BoxHeader="Mark Attendance">
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="workingFrom"
              value={workingFrom ?? ""}
              onChange={mdmChangeHandler}
            >
              <FormControlLabel
                value="Work From Office"
                control={<Radio />}
                label="Work From Office"
              />
              <FormControlLabel
                value="Work From Home"
                control={<Radio />}
                label="Work From Home"
              />
              <FormControlLabel
                value="work from client location"
                control={<Radio />}
                label="Work From Client Location"
              />
            </RadioGroup>
          </FormControl>

          <div style={{ display: "inline", marginLeft: "10px" }}>
            <button
              className="button-outlined-primary"
              onClick={() => CheckHandler("check_in")}
              style = {checkin === "Yes" ? stykeIn : {} }
              disabled={checkin === "Yes" ? true : false}
            >
              Attendance Check In
            </button>

            <button
              className="button-outlined-primary-cancel"
              onClick={() => CheckHandler("check_out")}
              style = { checkin === "No" ? stykeIn : checkout === "Yes" ? stykeIn  : {}  }
              disabled={
                checkin === "No" ? true : checkout === "Yes" ? true : false
              }
            >
              Attendance Check Out
            </button>
          </div>
        </div>
      </CustomeBox>

      <CustomeBox>Status: {handleDisplayMessage()}</CustomeBox>
    </>
    ) : (
      history.push("/")
    ) }
  </>
  );
}
