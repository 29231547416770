import React , {useEffect , useState} from 'react';
import CustomeBox from '../../Components/ui/CustomeBox';
import DataTable from '../../Components/ui/dataTable';
import axios from "../../Axios"
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

export default function LeaveHistoryAdmin() {
  const [records , setRecords ]  = useState([]);
  const [filterRecords , setFilterRecords ] = useState([]);
  const [dataFound , setDataFound] = useState(true);
  useEffect(() => {
    axios.get(`/getMonthLeaveReports`)
    .then( res => {
      if(res?.data?.status === true ) {
        setDataFound(true);
        setRecords(res.data.data);
        setFilterRecords(res.data.data);
      } else {
        toast.error(`${res.data.message}`);
        setDataFound(false);
      } 
    } )
    .catch( e => setDataFound(false) )
  } , [])

  

  useEffect( () => {
    if(records.length > 0 ) {
      const result = records.sort( (a , b ) =>  {
        return a.emp_id - b.emp_id  }
        )
        setRecords(result)
    }
  } , [records])


  const handleSearch = (e) => {
    let search = e.target.value;
    let data = filterRecords.filter((v) => {
      return (
        String(v.emp_name).toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });
    setRecords(data);
  };

  const headCells = [
    { id: "emp_id", label: "Emp Id" },
    { id: "emp_name", label: "Name", enableSorting: true },
    { id: "from_date", label: "From Date" },
    { id: "to_date", label: "To Date" },
    { id: "leave_type", label: "Leave Type" },
    { id: "leave_days", label: "Leave Days" },
    { id: "leave_status", label: "Leave Status" },
  ];

  return (
    <>
      <ToastContainer />
    <CustomeBox BoxHeader="Leave History">
    <Grid container spacing={3}>
    <Grid item xs={3}>
            <input
              type="text"
              onChange={handleSearch}
              placeholder="Search by name.."
              style={{
                border: "1px solid #343A40",
                borderRadius: "0.2rem",
                padding: "0.50rem",
                backgroundColor: "transparent",
                width: "100%",
                marginBottom: "20px",
              }}
            />
          </Grid>
    </Grid>
  
      <DataTable 
      records={records}
      headCells={headCells}
       enableEditButton={false}
       enableDeleteButton={false}
       enableShowButton={false}
       enableCustomButton={false}
       dataFound={dataFound}
       /> 
    </CustomeBox>
    </>
  )
}
