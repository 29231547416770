import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import SaveIcon from "@mui/icons-material/Save";
import Controls from "../ui/Controls/Controls";

const EditableRow = ({
  wbsMaster,
  wbs,
  setUserWbsList,
  userWbsList,
  setEditRowId,
}) => {
  const [selectedWbsCode, setSelectedWbsCode] = useState(wbs.wbs_code);
  const [selectedWbsDescription, setStateWbsDescription] = useState(
    wbs.wbs_description
  );
  const [percentage, setPercentage] = useState(wbs.wbs_percentage);

  const wbsCodeHandler = (e) => {
    e.preventDefault();
    setSelectedWbsCode(e.target.value);
    const result = wbsMaster.filter((item) => item.wbs_code === e.target.value);
    setStateWbsDescription(result[0].wbs_description);
  };

  const saveEditRow = () => {
    setEditRowId(null);
    if (wbs.wbs_code === selectedWbsCode && wbs.wbs_percentage === percentage) {
      console.log("");
    } else {
      console.log("  ");
      const form = {
        wbs_code: selectedWbsCode,
        wbs_description: selectedWbsDescription,
        wbs_percentage: Number(percentage),
      };
      const index = userWbsList.findIndex(
        (item) => item.wbs_code === wbs.wbs_code
      );
      userWbsList[index] = form;
    }

    setUserWbsList(userWbsList);
  };

  return (
    <tr>
      <td align="left" style={{ width: "30%", padding: "20px" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select WBS</InputLabel>
          <Select
            style={{ width: "100%" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={selectedWbsCode}
            value={selectedWbsCode}
            onChange={wbsCodeHandler}
            label="Select WBS"
            size="small"
          >
            {wbsMaster.map((item) => (
              <MenuItem key={item._id} value={item.wbs_code}>
                {item.wbs_code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </td>
      <td align="left" style={{ width: "50%", padding: "20px" }}>
        <TextField
          style={{ width: "100%" }}
          id="outlined-size-small"
          size="small"
          variant="outlined"
          label="WBS Description"
          value={selectedWbsDescription}
          readOnly
        />
      </td>
      <td align="left" style={{ width: "100%", padding: "20px" }}>
        <TextField
          style={{ width: "100%" }}
          id="outlined-number"
          variant="outlined"
          size="small"
          type="number"
          defaultValue={percentage}
          value={percentage}
          onChange={(e) => setPercentage(e.target.value)}
          onWheel={(e) => e.target.blur()}
          label="Enter Percentage"
        />
      </td>
      <td align="center">
        <Controls.ActionButton
          style={{
            background: "#343A40",
          }}
        >
          <SaveIcon
            style={{ color: "#fff", fontSize: "22px" }}
            onClick={saveEditRow}
          />
        </Controls.ActionButton>
      </td>
    </tr>
  );
};

export default EditableRow;
