import React from "react";
import ClearIcon from '@mui/icons-material/Clear';

export default function CustomeModal(props) {
  const { BoxHeader, BoxFooter , closeButton , closeFunction  } = props;
  const boxStyle = {
    margin: "2rem auto 10px",
    // boxShadow: "1px 5px 8px 0px #ddd",
    borderRadius: "5px",
    background: "#fff",
    width:"60%",
    
  };

  const BoxHeaderStyle = {
    paddingLeft: "10px",
    paddingRight:"10px",
    paddingTop: "10px",
    paddingBottom: "5px",
    background: "#343A40",
    borderRadius: "5px 5px 0px 0px",
    color:'#fff',
    borderBottom:'3px solid #F29535',
    display:"flex",
    justifyContent:"space-between"
  };
  const BoxContentStyle = {
    padding: "20px",
  };

  return (
    <>
      <div style={boxStyle}>
        <div className="boxHeader" style={BoxHeaderStyle}>
          <div><h5>{BoxHeader}</h5></div>
          {closeButton && (<div style={{cursor:"pointer"}}><ClearIcon onClick={closeFunction}/></div>)}
        </div>
        <div className="box-content" style={BoxContentStyle}>
          {props.children}
        </div>
        <div className="box-footer">{BoxFooter}</div>
      </div>
    </>
  );
}
