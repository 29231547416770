import React, { useState } from "react";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import axios from "../../Axios";
import NewFormTable from "./NewFormTable";
import Controls from "../../Components/ui/Controls/Controls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import { useHistory } from "react-router-dom";
import "../../containers/TimeSheet/timeSheet.css";
import Swal from "sweetalert2";

const EditableTable = ({
  existingDataForSelectedDate,
  setExistingDataForSelectedDate,
  wbsMaster,
  selectedDate,
  setShowEmptyTable,
  setShowDataTable,
  setSelectedDate,
  setStartDate,
  callinggetApi,
}) => {
  const history = useHistory();
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [editRowId, setEditRowId] = useState(null);

  const [userWbsList, setUserWbsList] = useState(existingDataForSelectedDate);

  //show input field if true
  const [isAddButtonEnabled, setIsAddButtonEnabled] = useState(false);

  const [triggerUpdateAndCancel , setTriggerUpdateAndCancel] = useState(false);
  
  const formatDate = (date) => {
    return date;
  };

  const clickEditHandler = (event, data) => {
    event.preventDefault();
    setTriggerUpdateAndCancel(true)
    setEditRowId(data);
    setIsEditEnabled(true);
  };

  const updateTimeSheetHandler = async () => {

    let total = 0;
    if (userWbsList.length === 1) {

      if (userWbsList[0].wbs_percentage === 100) {
        total = 100;
      }
    } else {

      total = userWbsList.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.wbs_percentage);
      }, 0);
    }
    const empUpdatedTimeSheet = {
      emp_id: localStorage.getItem("empId"),
      timesheet_date: formatDate(selectedDate),
      details: userWbsList,
    };
    console.log("Total ==>>> " , total);
    if (total === 100) {
      const response = await axios.post(
        `/createTimesheet`,
        empUpdatedTimeSheet
      );
      if (response.data.status === true) {
        Swal.fire({
          icon: "success",
          title: 'Successfully Updated TimeSheet',
          timer: 2800,
          showConfirmButton: true
        })
        setShowDataTable(false);
        setShowEmptyTable(false);
        setExistingDataForSelectedDate([]);
        // setStartDate(null);
        setSelectedDate(null);
        callinggetApi(
          new Date(selectedDate)
            .toLocaleString("en-GB", "Asia/Kolkata")
            .split(",")[0]
            .split("/")
            .join("-")
        );
        history.push("/timeSheet");
      } else if (response.data.status === false) {
        Swal.fire({
          icon: "error",
          html: `<div>
          ${response.data.msg}
          </div>`,
          timer: 2800,
          showConfirmButton: true
        })
        // toast.error(response.data.msg, Toaster);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: 'Total percentage should be 100',
        timer: 2800,
        showConfirmButton: true
      })
      // toast.error("total percentage should be 100", Toaster);
    }
  };

  const handleDeleteItem = (event, data) => {
    setTriggerUpdateAndCancel(true);
    const newItems = [...userWbsList];
    const index = userWbsList.findIndex((item) => item.wbs_code === data);
    newItems.splice(index, 1);
    existingDataForSelectedDate = newItems;
    setUserWbsList(newItems);
  };

  const showInputFieldHandler = () => {
    setTriggerUpdateAndCancel(true)
    if (userWbsList.length < 5) {
      setIsAddButtonEnabled(true);
    } else {
      toast.error("You can add only up to 5 WBS", Toaster);
      setTriggerUpdateAndCancel(false);
    }
  };

  const cancelUpdateHandler = () => {
    setIsAddButtonEnabled(false);
    setIsEditEnabled(false);
    setTriggerUpdateAndCancel(false);
  };

  return (
    <div>
      {!isAddButtonEnabled && (
        <Controls.Button
          style={{
            marginLeft: "1rem",
            padding: "0.4rem 1.5rem",
            float: "right",
            marginBottom:"10px"
          }}
          onClick={showInputFieldHandler}
          text="Add New WBS"
        />
      )}
      <TableContainer>
        <Table style={{ width: "100%" }}>
          <TableHead style={{background:"#c2c7d0" , borderRadius:"3px 3px 0 0" }}>
            <TableRow>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "16px", width: "35%" , padding:"10px" }}
                align="center"
              >
                 Select Project Name (WBS)
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "16px", width: "35%" , padding:"10px" }}
                align="center"
              >
                WBS Description
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "16px", width: "10%" , padding:"10px" }}
                align="center"
              >
                Percentage 
              </TableCell>
              <TableCell
                style={{ fontWeight: "bold", fontSize: "16px", width: "10%" , padding:"10px" }}
                align="center"
              >
                Action 
              </TableCell>
              <TableCell style={{ width: "20%" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {userWbsList.map((wbs , index) => (
              <>
                {isEditEnabled && wbs.wbs_code === editRowId ? (
                  <EditableRow
                    key={wbs.wbs_code}
                    wbsMaster={wbsMaster}
                    wbs={wbs}
                    setUserWbsList={setUserWbsList}
                    userWbsList={userWbsList}
                    setEditRowId={setEditRowId}
                  />
                ) : (
                  <ReadOnlyRow
                    index={index}
                    wbs={wbs}
                    key={wbs.wbs_code}
                    clickEditHandler={clickEditHandler}
                    handleDeleteItem={handleDeleteItem}
                  />
                )}
              </>
            ))}
            {/* {arrayToAddRow.map( v => {
              return (
                <NewFormTable
                wbsMaster={wbsMaster}
                userWbsList={userWbsList}
                setUserWbsList={setUserWbsList}
                setIsAddButtonEnabled={setIsAddButtonEnabled}
              />
              )
            })} */}
            {isAddButtonEnabled && (
              <NewFormTable
                wbsMaster={wbsMaster}
                userWbsList={userWbsList}
                setUserWbsList={setUserWbsList}
                setIsAddButtonEnabled={setIsAddButtonEnabled}
              />
            )}
          </TableBody>
        </Table>

        {triggerUpdateAndCancel && (
            <div className="btnTimeSheet">
            <Controls.Button
              style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
              onClick={updateTimeSheetHandler}
              text="update"
            />
            <Controls.ButtonV2
              style={{ marginLeft: "1rem" }}
              onClick={cancelUpdateHandler}
              text="cancel"
            />
          </div>
        )}
        
      </TableContainer>
    </div>
  );
};

export default EditableTable;
