import React, { useRef, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import CustomeModal from "../../Components/ui/CustomeModal";
// import Card from "../../Components/customCard";
import Controls from "../../Components/ui/Controls/Controls";
import axios from "../../Axios";
import CustomCard from "../../Components/cards/CustomeCard";

const ShowLeave = ({ item, open, close }) => {
  const [errorFromApi, setErrorFromApi] = useState(false);
  const [dateFromApi, setDateFromApi] = useState([]);
  console.log(item);
  useEffect(() => {
    axios
      .post(`/getoneLeaveBalance`, { emp_id: item.emp_id })
      .then((res) => {
        if (res.data.status === true) {
          setDateFromApi(res?.data?.data);
        } else {
          setErrorFromApi(true);
        }
      })
      .catch((err) => setErrorFromApi(true));
  }, []);

  console.log(dateFromApi);
  return (
    <div>
      <Modal
        open={true}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomeModal
          BoxHeader="Leave Balance"
          closeButton={true}
          closeFunction={close}
        >
          <Box>
            {errorFromApi ? (
              <div>No Data Found!!</div>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <CustomCard
                      title="CASUAL LEAVE:"
                      value={dateFromApi?.cl_bal}
                      bottomColor="#40A1FA"
                      backgroundcolor="rgb(244, 246, 249)"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCard
                      title="EARNED LEAVE:"
                      value={dateFromApi?.el_bal}
                      bottomColor="#DC3545"
                      backgroundcolor="rgb(244, 246, 249)"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <CustomCard
                      title="COMPENSATORY LEAVE:"
                      value={dateFromApi?.co_bal}
                      bottomColor="green"
                      backgroundcolor="rgb(244, 246, 249)"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <CustomCard
                      title="PATERNITY LEAVE:"
                      value={dateFromApi?.pl_bal}
                      bottomColor="rgb(242, 150, 52)"
                      backgroundcolor="rgb(244, 246, 249)"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}></Grid>

                  <Grid item xs={12} md={4}>
                    <CustomCard
                      title="MATERNITY LEAVE:"
                      value={dateFromApi?.ml_bal}
                      bottomColor="#343A40"
                      backgroundcolor="rgb(244, 246, 249)"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </CustomeModal>
      </Modal>
    </div>
  );
};

export default ShowLeave;
