import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import { NavLink } from "react-router-dom";
import Image from "../../assets/botmantra-webillustration.png";

import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";

import "./layout.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  listStyleOnHover: {
    "& span": {
      color: "#c2c7d0",
    },
    "&:hover": {
      background: "rgba(255,255,255,.1)",

      margin: "0px 0px",
    },

    "&:hover span": {
      color: "#fff",
    },
  },
  activeClass: {
    fontWeight: "bold",
    color: "#fff",
    background: "rgba(255,255,255,.1)",
    marginLeft: "0px",
    // borderTopLeftRadius: "5px",
    // borderBottomLeftRadius:'5px',
    borderLeft: "5px solid #F29634",
    "& span": {
      color: "inherit",
    },
  },

  subMenu: {
    marginLeft: "15px",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#343A40",
    // boxShadow: "1px 2px 8px 1px #fff"
    borderRight: "none",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",

    padding: theme.spacing(0, 0),
    // background: "#fafafa",

    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },

  drawerHeaderSpace: {
    display: "flex",
    alignItems: "center",
    // marginTop: "42px",
    padding: theme.spacing(0, 0),
    // background: "#fafafa",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  listStyle: {
    overflowY: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

// ---layout component---
export default function PersistentDrawerLeft(props) {
  const { name, setName } = props;
  const role = localStorage.getItem("status");

  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };


  const menuId = "primary-search-account-menu";
  // ---Logout funtion---
  const handleLogout = () => {
    localStorage.clear();
    setName(null);
    history.push("/");
  };
  // ---Handle profile function---
  const handleChangePassword = (props) => {
    history.push("/changePassword");
  };

  // ---Handle home function--
  // const handleHomeButton = () => {
  //   history.push("/home");
  // };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* ---Appbar--- */}
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ background: "#fff" }}
      >
        <Toolbar>
          <IconButton
            color="#000"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div
            className="nav-styling"
            style={{
              marginLeft: "auto",
              textTransform: "capitalize",
              color: "#000",
            }}
          >
            {name ? name : localStorage.getItem("empName") }
            {/* <Tooltip title="Home">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleHomeButton}
                color="inherit"
              >
                <HomeIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Change Password">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleChangePassword}
                color="inherit"
              >
                <LockOpenRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleLogout}
                color="inherit"
              >
                <ExitToAppIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {/* ---End--- */}
      {/* ---Side drawer--- */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/* sidebar header  */}
        <div className={classes.toolbar}>
          <div
            style={{
              height: "64px",
              padding: "10px",
              paddingLeft: "30px",
              background: "#fff",
            }}
          >
            <img src={Image} alt="logo" height="100%" width="60%" />
          </div>
        </div>
        {/* ---Menus--- */}
        <div className="sidebar">
          <div className="sidebarMenus">
            <List style={{ padding: "0px" }}>
              {/* 3.Mark Your Time Sheet */}
              {role === "Employee" && (
                <>
                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/timeSheet`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Time Sheet"} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/leaveMgmt`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Leave Management"} />
                  </ListItem>
                  <Divider />
                   <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/mark-attendance`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Mark Attendance"} />
                  </ListItem>
                  <Divider />
                </>
              )}

              {role === "Admin" && (
                <>
                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/leaveApproval`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Leave Approval"} />
                  </ListItem>

                  <Divider />

                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/leave-history`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Leave History"} />
                  </ListItem>

                  <Divider />

                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/download-attendance-sheet`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Download Attendance Sheet"} />
                  </ListItem>

                  <Divider />

                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/leaveTransaction`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Leave Transaction"} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/empList`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Employee Master"} />
                  </ListItem>
                  <Divider />
                  <ListItem
                    className={classes.listStyleOnHover}
                    button
                    component={NavLink}
                    to={`/projectMaster`}
                    activeClassName={classes.activeClass}
                  >
                    <ListItemText primary={"Project Master"} />
                  </ListItem>
                </>
              )}

              <ListItem
                className={classes.listStyleOnHover}
                button
                component={NavLink}
                to={`/holidays`}
                activeClassName={classes.activeClass}
              >
                <ListItemText primary={"Holiday List"} />
              </ListItem>
            </List>
          </div>
        </div>

        {/* ---sidebar footer--- */}
        {/* <div className="Footer">
                    Powered by Botmantra&trade;
                </div> */}
        <div className="footer-style">
          <Typography variant="subtitle2">
          &#xA9; All Rights Reserved. Bot <span style={{ color: "#F29634" }}>Mantra</span> - {new Date().getFullYear()}.                
            
            {/* &#xA9; Copyright BOT<span style={{ color: "#F29634" }}>mantra </span>
             {new Date().getFullYear()}{" "} */}
          </Typography>
        </div>
      </Drawer>
      {/* ---End--- */}
      {/*---main content of dashboards start---*/}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeaderSpace} />
        {props.children}
        {/* ............. */}
      </main>
      {/*---End---*/}
    </div>
  );
}
