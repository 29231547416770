import React, { useState, useEffect } from "react";
import DataTable from "../../Components/ui/dataTable";
import CustomeBox from "../../Components/ui/CustomeBox";
import EditLeave from "./EditLeave";
import axios from "../../Axios";
import { Grid } from "@mui/material";
import Controls from "../../Components/ui/Controls/Controls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import ApprovePopup from "../../Components/PopUp/ApprovePopup";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


const headCells = [
  { id: "emp_id", label: "Emp Id" },
  { id: "emp_name", label: "Name" },
  { id: "emp_email", label: "Email" },
  { id: "el_bal", label: "Earned Leave" },
  { id: "cl_bal", label: "Casual Leave" },
  { id: "co_bal", label: "Comp Off" },
  { id: "ml_bal", label: "Maternity Leave" },
  { id: "pl_bal", label: "Paternity Leave" },
  { id: "action", label: "Action" },
];

const LeaveTransaction = () => {
  const [empLeave, setEmpLeave] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const [isCreditEarnedLeave, setIsCreditEarnedLeave] = useState(false);
  const [isCreditCasualLeave, setIsCreditCasualLeave] = useState(false);

  const [popUpOpen, setPopUpOpen] = useState(false);

  const [dataFound, setDataFound] = useState(true);

  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const [triggerForButtonUploadExcel , setTriggerForButtonUploadExcel] = useState(false);

  const getLeaveTransaction = () => {
    try {
      const LeaveTransactionList = async () => {
        const response = await axios.get("/leaveTransaction");
        if(response?.data?.status === false) {
      toast.error(`${response?.data?.message}`, Toaster);
      setDataFound(false);
        }
        if (response.data.data.length === 0) {
          setDataFound(false);
        } else {
          setDataFound(true);
          setEmpLeave(response.data.data);
          setFilteredRows(response.data.data);
        }
      };
      LeaveTransactionList();
    } catch (err) {
      setDataFound(false);
      toast.error(err, Toaster);
    }
  };

  const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const exportToExcel = () => {

    const contentType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const filename = `Leave Details.xlsx`;


    axios.get("/getleavebalexcel")
    .then( (res) => {
      if(res.data.status === true) {
      downloadBase64File(contentType, res.data.file, filename);
      } else {
        alert("Something Went Wrong");
      }
    } )
    .catch( e => alert("Unable To Download Try After Some Time") );
  };

  const handleFileUpload = (e) => {
    let filedataForUpload = e.target.files[0];
    const filedata = new FileReader();
    filedata.readAsDataURL(filedataForUpload);
    filedata.onload = function () {
      let readData = filedata.result;
      setTriggerForButtonUploadExcel(true);
      axios.post(`/updateLeaveBalExcel` , {
        get_base64:readData.split(",")[1]
      }).then( res => {
        if(res.data.status === false) {
          setTriggerForButtonUploadExcel(false)
          getLeaveTransaction();
          toast.error(res.data.message, Toaster);
        } else {
          setTriggerForButtonUploadExcel(false)
          toast.success(res.data.message, Toaster);
        }
      } ).catch( () => {
        toast.error("Server Error Try After Some Time", Toaster);
      })
    };
     
  }

  useEffect(() => {
    getLeaveTransaction();
  }, []);

  const editHandler = (item) => {
    setIsEdit(true);
    setEditData(item);
  };

  const enableEarnedLeaveCredit = () => {
    setIsCreditCasualLeave(false);
    setIsCreditEarnedLeave(true);
    setPopUpOpen(true);
  };

  const enableCasualLeaveCredit = () => {
    setIsCreditEarnedLeave(false);
    setIsCreditCasualLeave(true);
    setPopUpOpen(true);
  };

  const earnedLeaveCreditHandler = async () => {
    setIsLoaderActive(true);
    try {
      const response = await axios.post("/leaveCreaditEL", {
        leaveType: "EL",
        data: "true",
      });
      if (response.data.status === true) {
        toast.success(response.data.message, Toaster);
        getLeaveTransaction();
      } else {
        toast.error(response.data.message, Toaster);
      }
      setIsCreditEarnedLeave(false);
      setIsCreditCasualLeave(false);
      setIsLoaderActive(false);
      setPopUpOpen(false);
    } catch (err) {
      toast.error("Something Went Wrong", Toaster);
      setIsLoaderActive(false);
      setIsCreditEarnedLeave(false);
      setIsCreditCasualLeave(false);
      setPopUpOpen(false);
    }
  };

  const casualLeaveCreditHandler = async () => {
    setIsLoaderActive(true);
    try {
      const response = await axios.post("/leaveCreaditCL", {
        leaveType: "CL",
        data: "true",
      });
      if (response.data.status === true) {
        toast.success(response.data.message, Toaster);
        getLeaveTransaction();
      } else {
        toast.error(response.data.message, Toaster);
      }
      setIsCreditCasualLeave(false);
      setIsCreditEarnedLeave(false);
      setIsLoaderActive(false);
      setPopUpOpen(false);
    } catch (err) {
      toast.error("Something Went Wrong", Toaster);
      setIsCreditCasualLeave(false);
      setIsCreditEarnedLeave(false);
      setIsLoaderActive(false);
      setPopUpOpen(false);
    }
  };

  const handleSearch = (e) => {
    let search = e.target.value;
    let data = filteredRows.filter((v) => {
      return (
        String(v.emp_name).toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });
    setEmpLeave(data);
  };

  return (
    <>
      <ToastContainer />
      <CustomeBox BoxHeader="Leave Details">
        {isEdit && (
          <EditLeave
            editData={editData}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            getLeaveTransaction={getLeaveTransaction}
          />
        )}

        <Grid container spacing={3}>
          <Grid item xs={3}>
            <input
              type="text"
              onChange={handleSearch}
              placeholder="Search by name.."
              style={{
                border: "1px solid #343A40",
                borderRadius: "0.2rem",
                padding: "0.50rem",
                backgroundColor: "transparent",
                width: "100%",
                marginBottom: "20px",
              }}
            />
          </Grid>
          <Grid xs={3}>
          <Controls.Button
              fullWidth={false}
              style={{marginTop:"24px" , marginLeft:"10px" , padding:"6px 16px"}}
              text="Export to excel"
              onClick={exportToExcel}
              size="small"
            />
          </Grid>
          <Grid xs={4}>

          </Grid>
          <Grid xs={2}>
          <Button disabled={triggerForButtonUploadExcel ? true : false} component="label" variant="contained"  style={{background:"#343A40", marginTop:"23px" , marginLeft:"50px" , border:"1px solid #F2963"}}>
           {triggerForButtonUploadExcel ? <div style={{color:"white" , alignItems:"center" , display:"flex" }}><CircularProgress style={{color:"#fff"}} disableShrink size={20}/> {" "} uploading</div> : "Upload Excel"}
           <input type="file" hidden onChange={handleFileUpload} />
            </Button>
          </Grid>
          {/* <Grid item xs={1}>
            <Controls.Button
              fullWidth={true}
              text="CL"
              onClick={enableCasualLeaveCredit}
            />
          </Grid>
          <Grid item xs={1}>
            <Controls.Button
              fullWidth={true}
              text="EL"
              onClick={enableEarnedLeaveCredit}
            />
          </Grid> */}
        </Grid>

        <DataTable
          records={empLeave}
          headCells={headCells}
          editOnclickFn={editHandler}
          enableEditButton={true}
          dataFound={dataFound}
        />
      </CustomeBox>
      {isCreditEarnedLeave && (
        <ApprovePopup
          approveFunc={earnedLeaveCreditHandler}
          popUpOpen={popUpOpen}
          setPopUpOpen={setPopUpOpen}
          header="Are you sure you want increment Earned leave ?"
          isLoaderActive={isLoaderActive}
        />
      )}
      {isCreditCasualLeave && (
        <ApprovePopup
          approveFunc={casualLeaveCreditHandler}
          popUpOpen={popUpOpen}
          setPopUpOpen={setPopUpOpen}
          header="Are you sure you want increment Casual leave ?"
          isLoaderActive={isLoaderActive}
        />
      )}
    </>
  );
};

export default LeaveTransaction;
