import React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Controls from "../../Components/ui/Controls/Controls";
import Box from "@mui/material/Box";
import Loader from "../../assets/loader.gif";
import Grid from "@mui/material/Grid";

const style = {
  textAlign: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const ApprovePopup = ({
  header,
  approveFunc,
  popUpOpen,
  setPopUpOpen,
  isLoaderActive,
  inputFieldForRejection,
  valueForInputField,
  inputFieldErrorMessageTrueOrFalse,
  showInputField
}) => {
  const handleClose = () => {
    setPopUpOpen(false);
  };
  return (
    <Modal
      open={popUpOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {isLoaderActive ? (
          <>
            <img src={Loader} alt="Loader"></img>
            {/* <p>Loading Please Wait</p> */}
          </>
        ) : (
          <>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {header}
            </Typography>

            {showInputField && (
             <Grid item xs={12} md={12}>
             {/* <label> */}
               <p style={{textAlign:"left" , color:"black" , marginBottom:"5px" }}>
               Reason for Rejecting<sup style={{color:"red"}}>*</sup>:
               </p>
             {/* </label> */}
             <textarea
               style={{
                 width: "100%",
                 borderRadius: "2px",
                 border: "1px solid #ddd4d4",
               }}
               value={valueForInputField}
               onChange={(e) => {
                 inputFieldForRejection(e)
                 // setReasonForLeave(e.target.value);
                 // setLeaveError(false);
               }}
             ></textarea>
             {inputFieldErrorMessageTrueOrFalse ? (
               <p style={{ color: "red" , textAlign:"left" }}>Required Field</p>
             ) : (
               ""
             )}
           </Grid>
            )}
            
            <div style={{ float: "center", marginTop: "1rem" }}>
              <Controls.Button
                style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                onClick={approveFunc}
                text="yes"
              />

              <Controls.ButtonV2
                style={{ marginLeft: "1rem" }}
                onClick={handleClose}
                text="no"
              />
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ApprovePopup;
