import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Controls from "./Controls/Controls";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DangerousRoundedIcon from "@mui/icons-material/DangerousRounded";

const useStyles = makeStyles((theme) => ({
  table: {
    // marginTop: theme.spacing(1),
    "& thead th": {
      fontWeight: "600",
      color: "#000",
      backgroundColor: "#c2c7d0",
      padding: theme.spacing(1),
    },
    "& tbody td": {
      fontWeight: "300",
      padding: theme.spacing(1),
    },
    "& tbody tr:nth-child(even)":{
      backgroundColor:"#f3f5f8"
    },
    "& tbody tr:hover": {
      // backgroundColor: "#f4f3f8",
      cursor: "pointer",
    },
  },
}));

export default function DynamicDataTable(props) {
  const classes = useStyles();
  const pages = [10, 25, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const {
    records,
    headCells,
    editOnclickFn,
    deleteOnclickFn,
    showOnclickFn,
    customButtonOnclickFn,
    enableEditButton,
    enableDeleteButton,
    enableShowButton,
    enableCustomButton,
    dataFound,
    // enablePagination
  } = props;
  // ---------------------------------TABLE PAGINATION FUNCTION-----------------------------
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const TblPagination = (pages, rowsPerPage, records) => (
    <TablePagination
      component="span"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      // width="400px"
    />
  );
  // -------------------------------TABLE PAGINATION FUNCTION END-----------------------------

  // -----------------------------------TABLE SHORT FUNCTION----------------------------------
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  // -----------------------------------TABLE SHORT FUNCTION END---------------------------------

  // ----------------------------------------TABLE HEADER----------------------------------------
  const TblHead = (headCells) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.enableSorting ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  // ---------------------------------------TABLE HEADER END-----------------------------------------
  return (
    <>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>

      <Table className={classes.table} stickyHeader aria-label="sticky table">
        {TblHead(headCells)}
        <TableBody>
          {records.length >= 1 ? (
            stableSort(records, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((record) => {
                return (
                  <TableRow key={records.user_id}>
                    {headCells.map((hellCellObj) => {
                      return (
                        <>
                          {hellCellObj.id === "leave_status" &&
                            record[hellCellObj.id] === "APPLIED"}
                          {hellCellObj.id === "emp_status" ||
                          hellCellObj.id === "wbs_status" ? (
                            <TableCell>
                              {" "}
                              {record[hellCellObj.id] === "Active" ||
                              record[hellCellObj.id] === "A" ? (
                                <CheckCircleRoundedIcon
                                  style={{ color: "green" }}
                                />
                              ) : (
                                <DangerousRoundedIcon
                                  style={{ color: "red" }}
                                />
                              )}
                            </TableCell>
                          ) : hellCellObj.id !== "action" ? (
                            <TableCell> {record[hellCellObj.id]}</TableCell>
                          ) : (
                            <TableCell
                              style={{ padding: "0px", textAlign: "left" }}
                            >
                              {enableEditButton && editOnclickFn && (
                                <Controls.ActionButton
                                  color="primary"
                                  onClick={() => editOnclickFn(record)}
                                >
                                  <EditOutlinedIcon
                                    style={{ color: "black" }}
                                    fontSize="small"
                                  />
                                </Controls.ActionButton>
                              )}

                              {enableShowButton && showOnclickFn && (
                                <Controls.ActionButton
                                  color="primary"
                                  onClick={() => showOnclickFn(record)}
                                >
                                  <VisibilityIcon
                                    style={{ color: "black" }}
                                    fontSize="small"
                                  />
                                </Controls.ActionButton>
                              )}

                              {enableCustomButton && customButtonOnclickFn && (
                                <Controls.ActionButton
                                  style={{ backgroundColor: "#00b300" }}
                                  // color="primary"
                                  onClick={() => customButtonOnclickFn(record)}
                                >
                                  <DoneIcon
                                    style={{ color: "black" }}
                                    fontSize="small"
                                  />
                                </Controls.ActionButton>
                              )}

                              {enableDeleteButton && deleteOnclickFn && (
                                <Controls.ActionButton
                                  style={{ backgroundColor: "red" }}
                                  onClick={() => deleteOnclickFn(record)}
                                >
                                  <CloseIcon
                                    style={{ color: "black" }}
                                    fontSize="small"
                                  />
                                </Controls.ActionButton>
                              )}
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                  </TableRow>
                );
              })
          ) : (
            <>
              <TableRow>
                <TableCell
                  colSpan={headCells.length}
                  align="center"
                  style={{ color: "#343a40", fontWeight: "600" }}
                >
                  {dataFound ? (
                    "Loading, Please wait"
                  ) : (
                    <span style={{ color: "red" }}>No Data Found !</span>
                  )}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
      </TableContainer>
      </Paper>

      {TblPagination(pages, rowsPerPage, records)}
    </>
  );
}
