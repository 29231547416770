import React, { useState } from "react";
import Box from "@mui/material/Box";
// import { alpha, styled } from "@mui/material/styles";
// import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";

import axios from "../../Axios";
import CustomeBox from "../ui/CustomeBox";
import InputLabel from "@mui/material/InputLabel";
import "./ResetPassword.css";
// import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import RemoveCredentials from "../GlobalComponent/RemoveCredentials";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import Controls from "../../Components/ui/Controls/Controls";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const history = useHistory();
  const { Email } = useParams();

  const [newPwd, setNewPwd] = useState("");
  const [confirmCurrentPwd, setConfirmCurrentPwd] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const changePasswordHandler = async () => {
    setError(false);

    setErrorMessage("");
    try {
      if (newPwd === confirmCurrentPwd) {
        const response = await axios.post(`/ResetPassword/${Email}`, {
          //   current_password: currentPwd,
          new_password: newPwd,
          email_id: Email,
        });
        if (response.data.status === true) {
          toast.success(response.data.message, Toaster);
          RemoveCredentials();
          history.push("/login");
        } else if (response.data.status === false) {
          toast.error(response.data.message, Toaster);
        } else {
          toast.error(response.data.message, Toaster);
        }
        setNewPwd("");
        setConfirmCurrentPwd("");
      } else {
        setErrorMessage("New Password and Confirm Password does not match");
      }
    } catch (error) {
      setError(true);
    }
  };

  const navigateToFormHandler = () => {
    history.push("/");
    setNewPwd("");
    setConfirmCurrentPwd("");
  };

  // const BootstrapInput = styled(InputBase)(({ theme }) => ({
  //   "label + &": {
  //     marginTop: theme.spacing(3),
  //   },
  //   "& .MuiInputBase-input": {
  //     borderRadius: 4,
  //     position: "relative",
  //     backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
  //     border: "1px solid #ced4da",
  //     fontSize: 16,
  //     width: "auto",
  //     padding: "10px 12px",
  //     transition: theme.transitions.create([
  //       "border-color",
  //       "background-color",
  //       "box-shadow",
  //     ]),

  //     fontFamily: [
  //       "-apple-system",
  //       "BlinkMacSystemFont",
  //       '"Segoe UI"',
  //       "Roboto",
  //       '"Helvetica Neue"',
  //       "Arial",
  //       "sans-serif",
  //       '"Apple Color Emoji"',
  //       '"Segoe UI Emoji"',
  //       '"Segoe UI Symbol"',
  //     ].join(","),
  //     "&:focus": {
  //       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
  //       borderColor: theme.palette.primary.main,
  //     },
  //   },
  // }));

  if (error) {
    console.log("Error in reset password : ", error);
  }

  return (
    <div>
      <ToastContainer />
      <div style={{ width: "75%", margin: "auto" }}>
        <CustomeBox BoxHeader="Change Password">
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 0.5, width: "32ch" },
            }}
            style={{ textAlign: "center", marginBottom: "0.5rem" }}
            noValidate
            autoComplete="off"
          >
            <div>
              {errorMessage && (
                <p style={{ backgroundColor: "#FFCCCC" , padding:"10px" }}>{errorMessage}</p>
              )}
            </div>

            <div style={{ marginTop: "1rem" }}>
              <InputLabel
                style={{ width: "113%", fontSize: "1.25rem" }}
                shrink
                htmlFor="bootstrap-input"
              >
                New Password
              </InputLabel>
              <TextField
                id="standard-size-small"
                variant="standard"
                size="small"
                name="new_password"
                type="password"
                value={newPwd}
                onChange={(e) => {
                  setNewPwd(e.target.value);
                }}
                autoComplete="new_password"
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <InputLabel
                style={{ width: "121%", fontSize: "1.25rem" }}
                shrink
                htmlFor="bootstrap-input"
              >
                Confirm New Password
              </InputLabel>
              <TextField
                id="standard-size-small"
                variant="standard"
                size="small"
                type="password"
                name="confirmNewPwd"
                value={confirmCurrentPwd}
                onChange={(e) => setConfirmCurrentPwd(e.target.value)}
                autoComplete="confirm-new-password"
              />
            </div>

            <div style={{ float: "center", marginTop: "2rem" }}>
              <Controls.Button
                style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                onClick={changePasswordHandler}
                text="submit"
              />

              <Controls.ButtonV2
                style={{ marginLeft: "1rem" }}
                onClick={navigateToFormHandler}
                text="cancel"
              />
            </div>
          </Box>
        </CustomeBox>
      </div>
    </div>
  );
};

export default ResetPassword;
