import React, { useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { nanoid } from "nanoid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Swal from "sweetalert2";

const CustomTableBody = ({ wbsMaster, setWbsList, wbsList, uniqueKey }) => {
  const [selectedWbsCode, setSelectedWbsCode] = useState("");
  const [selectedWbsDescription, setSelectedWbsDescription] = useState("");
  const [percentage, setPercentage] = useState();
  const [trrigerPercentge , setTrrigerPercentge] = useState(false);
  // const 

  let data = "";


  const handlePercentageOnBlur = () => {
    if(percentage !== "" && percentage !== undefined){
     setTrrigerPercentge(true)
    }
  }

  const handleEdit = () => {
    const index = wbsList.findIndex(
          (deleteWbs) => selectedWbsCode === deleteWbs.wbs_code
        );
        wbsList.splice(index, 1);
        setSelectedWbsCode("");
        setSelectedWbsDescription("");
        setPercentage("");
        setTrrigerPercentge(false);
  }

  const wbsCodeHandler = (e) => {
    console.log(wbsList, e.target.value)

    let find = false

    wbsList?.map(item=>{
      if(item.wbs_code === e.target.value){
        find =true
      }
    })

    if(!find){
      setSelectedWbsCode((prevWbsCode) => {
        data = prevWbsCode;
      });
      if (data !== "") {
        const index = wbsList.findIndex((item) => item.wbs_code === data);
        wbsList.splice(index, 1);
      }
      setSelectedWbsCode(e.target.value);
      const result = wbsMaster.filter((item) => item.wbs_code === e.target.value);
      setSelectedWbsDescription(result[0].wbs_description);
    }else{
      Swal.fire({
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
        title: 'Error Already Present In The List'
      })
    }
  };

  const handleChange = () => {
    const newForm = {
      wbs_id: nanoid(),
      wbs_code: selectedWbsCode,
      wbs_description: selectedWbsDescription,
      wbs_percentage: Number(percentage),
    };
    

    const index = wbsList.findIndex(
      (item) => item.wbs_code === newForm.wbs_code
    );
    if (index === -1) {
      wbsList.push(newForm);
    } else {
      wbsList[index] = newForm;
    }

    setWbsList(wbsList);
  };

  const handlePercentage = (e) => {
    setPercentage(e.target.value);
  };

  const deleteWBSHandler = () => {
    const index = wbsList.findIndex(
      (deleteWbs) => selectedWbsCode === deleteWbs.wbs_code
    );
    wbsList.splice(index, 1);
    setSelectedWbsCode("");
    setSelectedWbsDescription("");
    setPercentage("");
  };

  if (
    selectedWbsCode !== "" &&
    selectedWbsDescription !== "" &&
    percentage !== ""
  ) {
    handleChange();
  }

  return (
    <TableBody>
      <TableCell style={{ width: "30%" , textAlign:"center" , maxHeight:"360px" }}>
        {selectedWbsDescription ? (
          <>
          {selectedWbsCode}
          </>
        ) : ( 
          <>
          <FormControl fullWidth>
          <InputLabel id="demo-select-small">Select WBS</InputLabel>
          <Select
            // style={{ width: "100%" }}
            labelId="demo-simple-select-label"
            id="demo-select-small"
            defaultValue={30}
            value={selectedWbsCode}
            onChange={wbsCodeHandler}
            label="Select WBS"
            size="small"
          >
            {wbsMaster?.map((item) => (
              <MenuItem key={item._id} value={item.wbs_code}>
                {item.wbs_code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </>
        )}
        
      </TableCell>
      <TableCell style={{ width: "50%" , textAlign:"center"}}>
        {selectedWbsDescription ? (
          <>
          {selectedWbsDescription}
          </>
        ) : (
          <>
           <input
          style={{ width: "100%" , border:"1px solid #ddd" , 
                   height:"40px" , borderRadius:"3px" , 
                   paddingLeft:"5px" }}
          // id="outlined-size-small"
          // size="small"
          // variant="outlined"
          placeholder="WBS Description"
          value={selectedWbsDescription}
          readOnly
        />
          </>
        )}
       
      </TableCell>
      <TableCell style={{ width: "50%" , textAlign:"center" }}>
        {trrigerPercentge ? (<>
        {percentage}
        </>) : (
          <>
          <TextField
          style={{ width: "100%" }}
          id="outlined-number"
          variant="outlined"
          size="small"
          type="number"
          value={percentage}
          onChange={handlePercentage}
          label="Percentage"
          onWheel={(e) => e.target.blur()}
          onBlur={handlePercentageOnBlur}
        />
          </>
        )}
      </TableCell>
      <TableCell style={{ textAlign:"center"}}>
      <span class="material-symbols-outlined" style={{cursor:"pointer" , color:"#343A40"}} 
            onClick={handleEdit}>
      edit_square
      </span>
      </TableCell>
      {/* <TableCell align="center">
        <Controls.ActionButton
          style={{ backgroundColor: "red" }}
          onClick={deleteWBSHandler}
        >
          <CloseIcon style={{ color: "black" }} fontSize="small" />
        </Controls.ActionButton>
      </TableCell> */}
    </TableBody>
  );
};

export default CustomTableBody;
