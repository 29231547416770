import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@mui/material/CardMedia";
import './customCard.css'

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
  
  
 
});

export default function HomeCard(props) {
  const classes = useStyles();

  return (
    
    <Card className={classes.root}>
      <div
        style={{
          background: `${props.backgroundColor}`,
          padding: "0.3rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: 'center'
        }}
      >
        <CardContent>
          <Typography
            style={{ color: "rgb(202 220 231)", fontSize: "14px" }}
            gutterBottom
            variant="h5"
            component="h2"
          >
            {props.card_title}
          </Typography>
          <Typography
            style={{ color: "#fff", fontWeight: "700", fontSize: '13px' }}
            variant="body1"
            color="textSecondary"
            component="p"
          >
            {props.card_content}
          </Typography>
        </CardContent>
        <CardMedia className="icons"
          style={{
            fontSize: "3rem",
            color: `${props.color}`,
            marginTop: "-1.2rem",
            width: "20%",
          }}
        >
          {props.icon}
        </CardMedia>
      </div>
    </Card>
  );
}
