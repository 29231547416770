import React, { useEffect } from "react";
import DataTable from "../../Components/ui/dataTable";
import CustomeBox from "../../Components/ui/CustomeBox";
import Controls from "../../Components/ui/Controls/Controls";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const headCells = [
  { id: "from_date", label: "From Date" },
  { id: "to_date", label: "To Date" },
  { id: "leave_type", label: "Leave Type" },
  { id: "leave_status", label: "Status" },
];

export default function LeaveTable({
  setOpen,
  leavesList,
  getLeaveDetails,
  dataFound,
}) {
  const openModal = (e) => {
    setOpen(true);
  };

  // useEffect(() => {
  //   getLeaveDetails();
  // }, [getLeaveDetails]);

  return (
    <>
      <ToastContainer />
      <CustomeBox BoxHeader="Leave History">
        <Controls.Button
          style={{
            marginLeft: "1rem",
            padding: "0.4rem 1.5rem",
            float: "right",
            marginBottom: "1rem",
          }}
          onClick={openModal}
          text="Apply Leave"
        />
        {/* {isApplyLeave ? (
          ""
        ) : ( 
          // <Button
          //   style={{ float: "right", marginBottom: "1rem" }}
          //   variant="contained"
          //   size="medium"
          //   onClick={(e) => setIsApplyLeave(!isApplyLeave)}
          // >
          //   {" "}
          //   Apply Leave
          // </Button>
         
        // )}*/}
        <DataTable
          records={leavesList}
          headCells={headCells}
          enableEditButton={false}
          enableDeleteButton={false}
          dataFound={dataFound}
        />
      </CustomeBox>
    </>
  );
}
