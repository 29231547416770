import React, { useState, useEffect } from "react";
import DataTable from "../../Components/ui/dataTable";
import AddProject from "./AddProject";
import EditProject from "./EditProject";
import axios from "../../Axios";
import CustomeBox from "../../Components/ui/CustomeBox";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";

const headCells = [
  { id: "wbs_code", label: "WBS Code" },
  { id: "wbs_description", label: "WBS Description" },
  { id: "wbs_status", label: "WBS Status" },
  { id: "action", label: "Action" },
];

const ProjectMaster = () => {
  const [projectList, setProjectList] = useState([]);
  const [status, setStatus] = useState("");
  const [dataFound, setDataFound] = useState(true);

  const getProjectList = () => {
    try {
      const getProjectList = async () => {
        const response = await axios.get("/getWbsAll");
        if (response.data.data.length === 0) {
          setDataFound(false);
        } else {
          setDataFound(true);
          setProjectList(response.data.data);
          setFilteredRows(response.data.data);
        }
      };
      getProjectList();
    } catch (err) {
      setDataFound(false);
      toast.error(err, Toaster);
    }
  };
  useEffect(() => {
    getProjectList();
  }, []);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState("");

  const [newProject, setNewProject] = useState({
    // data entered in the form
    wbs_code: "",
    wbs_description: "",
  });

  const [filteredRows, setFilteredRows] = useState([]);

  const editHandler = (item) => {
    setIsEdit(true);
    setEditData(item);
  };

  const handleAddFormChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const newFormData = { ...newProject };
    newFormData[fieldName] = fieldValue;
    setNewProject(newFormData);
  };

  const handleAddFormSubmit = async (e) => {
    e.preventDefault();
    if (newProject.wbs_code === "" || newProject.wbs_description === "") {
      setError(true);
      setOpen(true);
    } else {
      try {
        const newItem = {
          wbs_code: newProject.wbs_code,
          wbs_description: newProject.wbs_description,
        };
        const newItems = newItem;

        const response = await axios.post("/createWbs", newItems);

        if (response.data.status === true) {
          //  alert("Successfully Created Project");
          toast.success("Successfully Created Project", Toaster);
          getProjectList();
        } else if (response.data.status === false) {
          toast.error(response.data.message, Toaster);
        }
        setNewProject({
          wbs_code: "",
          wbs_description: "",
        });
        Array.from(document.querySelectorAll("input")).forEach(
          (input) => (input.value = "")
        );
        setError(false);
        setOpen(false);
      } catch (err) {
        toast.error(err, Toaster);
      }
    }
  };

  const handleSearch = (e) => {
    let search = e.target.value;
    let data = filteredRows.filter((v) => {
      return (
        String(v.wbs_code).toLowerCase().indexOf(search.toLowerCase()) >= 0
      );
    });
    if (data.length === 0) {
      setDataFound(false);
    } else {
      setDataFound(true);
      setProjectList(data);
    }
  };

  return (
    <>
      <ToastContainer />
      <CustomeBox BoxHeader="Project List">
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <input
              type="text"
              onChange={handleSearch}
              placeholder="Search by WBS Code.."
              style={{
                border: "1px solid #343A40",
                borderRadius: "0.2rem",
                padding: "0.50rem",
                marginTop: "0.7rem",

                width: "30%",
                backgroundColor: "transparent",
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <AddProject
              open={open}
              setOpen={setOpen}
              error={error}
              setError={setError}
              handleAddFormChange={handleAddFormChange}
              handleAddFormSubmit={handleAddFormSubmit}
            />
          </Grid>
        </Grid>

        {isEdit && (
          <EditProject
            editData={editData}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
            setStatus={setStatus}
            status={status}
            getProjectList={getProjectList}
          />
        )}

        <DataTable
          records={projectList}
          headCells={headCells}
          editOnclickFn={editHandler}
          enableEditButton={true}
          dataFound={dataFound}
          // deleteOnclickFn={deleteHandler}
          // enableDeleteButton={true}
        />
      </CustomeBox>
    </>
  );
};

export default ProjectMaster;
