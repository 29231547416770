import React, { useState, useRef, useEffect } from "react";
// import CustomCard from "../../Components/customCard";
import CustomCard from "../../Components/cards/CustomeCard";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LeaveTable from "./LeaveTable";
import "./leaveMgmt.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import ValidatedDatePicker from "../../Components/ui/ValidatedDatePicker";
import axios from "../../Axios";
import subDays from "date-fns/subDays";
import { useForm } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@material-ui/core/Select";
import CustomeModal from "../../Components/ui/CustomeModal";
import Controls from "../../Components/ui/Controls/Controls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toaster from "../../Components/ui/Toaster";
import Modal from "@mui/material/Modal";
import { useHistory } from "react-router-dom";

const LeaveMgmt = () => {
  const history = useHistory();
  const { register } = useForm();
  const inputEl = useRef(null);
  const [leaveType, setLeaveType] = useState("");
  const [isLeaveSelected, setIsLeaveSeleted] = useState(false);

  // const [isApplyLeave, setIsApplyLeave] = useState(false);

  //DATE pICKER DATE
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [toDateError, setToDateError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);

  const [reasonForLeave, setReasonForLeave] = useState("");
  const [leaveError, setLeaveError] = useState(false);

  const [timeDifference, setTimeDifference] = useState(0);

  const [empLeaveBalance, setEmpLeaveBalance] = useState([]);

  const [fileData, setFileData] = useState("");

  const [open, setOpen] = React.useState(false);

  const [leavesList, setLeavesList] = useState([]);

  const [dataFound, setDataFound] = useState(true);

  const applyLeaveHandler = async () => {
    try {
      const detail = {
        from_date: fromDate.split("-").reverse().join("-"),
        to_date: toDate.split("-").reverse().join("-"),
        leave_type: leaveType,
        leave_days: Number(timeDifference.split(" ")[0]),
        reason_for_leave: reasonForLeave,
        leave_file: fileData,
      };

      const response = await axios.post("/applyLeave", detail);
      if (response.data.status === true) {
        toast.success(response.data.message, Toaster);
        history.push("/leaveMgmt");
      } else if (response.data.status === false) {
        toast.error(response.data.message, Toaster);
      }
      getLeaveDetails();
      setReasonForLeave();
      setFromDate(null);
      setToDate(null);
      setReasonForLeave("");
      setTimeDifference(0);
      setLeaveType("");
      setOpen(false);
    } catch (err) {
      toast.error(err, Toaster);
    }
  };

  if (dataFound) {
  }

  const cancelHandler = async () => {
    setOpen(false);
    setFromDate(null);
    setToDate(null);
    setFileData("");
    setReasonForLeave("");
    setFromDateError(false);
    setToDateError(false);
    setLeaveType("");
    setLeaveError(false);
  };

  const handleFromDateChange = (v) => {
    setToDate(null);
    setFromDateError(false);
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    setFromDate(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
  };

  const handleToDateChange = (v) => {
    setToDateError(false);
    setTimeDifference(0);
    const pad = "00";
    const yy = v.getFullYear().toString();
    const mm = (v.getMonth() + 1).toString();
    const dd = v.getDate().toString();
    const selectedToDate = `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(
      -2
    )}`;
    setToDate(`${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`);
    timeDifferenceHandler(fromDate, selectedToDate);
  };

  const timeDifferenceHandler = (fromDate, toDate) => {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
    setTimeDifference(diffDays + " day(s)");
  };

  const handleApplyHandler = () => {
    if (fromDate === null) {
      setFromDateError(true);
    } else if (toDate === null) {
      setToDateError(true);
    } else if (!leaveType) {
      setIsLeaveSeleted(true);
    } else if (leaveError) {
      setLeaveError(true);
    } else {
      applyLeaveHandler();
    }
  };

  const handleData = () => {
    console.log("");
  };

  useEffect(() => {
    try {
      const formatLeaveDetail = async () => {
        const response = await axios.get("/getLeaveBalance");
        setEmpLeaveBalance(response.data.data);
      };
      formatLeaveDetail();
    } catch (err) {
      Toaster.error(err, Toaster);
    }
  }, []);

  const getLeaveDetails = async () => {
    try {
      const response = await axios.get("/getLeaveDetails");
      if (response.data.data.length === 0) {
        setDataFound(false);
      } else {
        setDataFound(true);
        setLeavesList(response.data.data);
      }
    } catch (err) {
      toast.error(err, Toaster);
    }
  };

  useEffect(() => {
    getLeaveDetails();
  }, []);

  const handleFileUpload = (e) => {
    let filedataForUpload = e.target.files[0];
    const filedata = new FileReader();
    filedata.readAsDataURL(filedataForUpload);
    filedata.onload = function () {
      let readData = filedata.result;
      setFileData(readData);
    };
  };

  return (
    <>
      <ToastContainer />
      <Box style={{ marginBottom: "2rem" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
           <CustomCard 
            title="Earned Leave:"
            value={empLeaveBalance?.el_bal}
            bottomColor="#40A1FA"
            backgroundcolor="#fff"
           />
            
            {/* <CustomCard
              card_title="Earned Leave "
              card_content={empLeaveBalance.el_bal}
              backgroundColor="#17a2b8"
              color="#148a9d"
              icon={<BadgeIcon />}
            /> */}
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomCard
              title="Casual Leave:"
              value={empLeaveBalance?.cl_bal}
              bottomColor="#DC3545"
              backgroundcolor="#fff"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomCard
              title="Compensatory off:"
              value={empLeaveBalance?.co_bal}
              bottomColor="green"
              backgroundcolor="#fff"
            />
          </Grid>
        </Grid>
      </Box>
      {/* {isApplyLeave && ( */}
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomeModal
          BoxHeader="Apply Leave Form Section"
          style={{ marginTop: "2.5rem" }}
        >
          <div className="layout-styling">
            <div style={{ margin: "0 2rem", float: "right" }}>
              <lable className="label">Days Leave Applied For - </lable>{" "}
              <p style={{ display: "inline", fontWeight: "600" }}>
                {timeDifference}
              </p>
            </div>
            <div>
              <ValidatorForm
                ref={inputEl}
                onSubmit={handleData}
                onError={(errors) => console.log(errors)}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} md={3}>
                    <InputLabel id="demo-simple-select-label">
                      Select Leave Type
                    </InputLabel>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={leaveType}
                        label=""
                        variant="outlined"
                        onChange={(e) => {
                          setLeaveType(e.target.value);
                          setIsLeaveSeleted(false);
                        }}
                        size="small"
                      >
                        <MenuItem value="CL">Casual Leave</MenuItem>
                        <MenuItem value="EL">Earned Leave</MenuItem>
                        <MenuItem value="CO">Compensatory Off</MenuItem>
                        <MenuItem value="ML">Maternity Leave</MenuItem>
                        <MenuItem value="PL">Paternity Leave</MenuItem>
                      </Select>
                      {!isLeaveSelected ? (
                        ""
                      ) : (
                        <p style={{ color: "red" }}>Required Field</p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label style={{ display: "block" }}>
                      From Date
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <ValidatedDatePicker
                      value={fromDate}
                      minDateForDatepicker={subDays(new Date(), 11)}
                      onChange={(v) => handleFromDateChange(v)}
                    />
                    {fromDateError ? (
                      <p style={{ color: "red" }}>Required Field</p>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label>
                      To Date
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <ValidatedDatePicker
                      minDateForDatepicker={fromDate}
                      value={toDate}
                      onChange={(v) => handleToDateChange(v)}
                    />
                    {toDateError ? (
                      <p style={{ color: "red" }}>Required Field</p>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label>Upload Documents</label>
                    <Button
                      style={{ width: "100%" }}
                      variant="outlined"
                      component="label"
                    >
                      {" "}
                      <input
                        {...register("fileUpload")}
                        type="file"
                        onChange={handleFileUpload}
                      />
                      {/* Upload File{" "} */}
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <label>
                      Reason for Leave
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <textarea
                      style={{
                        width: "100%",
                        borderRadius: "0.3rem",
                        border: "1px solid #ddd4d4",
                      }}
                      value={reasonForLeave}
                      onChange={(e) => {
                        setReasonForLeave(e.target.value);
                        setLeaveError(false);
                      }}
                    ></textarea>
                    {leaveError ? (
                      <p style={{ color: "red" }}>Required Field</p>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={7}></Grid>
                  <Grid item xs={12} md={5}>
                    <div style={{textAlign:"end"}}>
                    <Controls.Button
                      style={{ marginLeft: "1rem", padding: "0.4rem 1.5rem" }}
                      onClick={handleApplyHandler}
                      text="apply"
                    />
                    <Controls.ButtonV2
                      style={{ marginLeft: "1rem" }}
                      onClick={cancelHandler}
                      text="cancel"
                    />
                    </div>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </div>
        </CustomeModal>
      </Modal>
      {/* )} */}

      <LeaveTable
        setOpen={setOpen}
        leavesList={leavesList}
        getLeaveDetails={getLeaveDetails}
        // setIsApplyLeave={setIsApplyLeave}
        // isApplyLeave={isApplyLeave}
      />
    </>
  );
};

export default LeaveMgmt;
